import React from "react";
import { Provider } from "react-redux";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { store } from "./store";
import HomePageRouter from "./routes/HomePageRouter";

function AppDashboard() {
  return (
    <Provider store={store}>
      <MantineProvider withGlobalClasses withCssVariables>
        <Notifications />
        <ModalsProvider>
          <HomePageRouter />
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  );
}

export default AppDashboard;
