import {
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ChevronDown } from "tabler-icons-react";
import { NavLink } from "react-router-dom";
import classes from "./HeaderMenu.module.css";

export function HeaderMenu({
  links = [
    { link: "", label: "", icon: null, links: [{ link: "", label: "" }] },
  ],
}) {
  const [opened, { toggle }] = useDisclosure(false);
  // const { classes } = useStyles();

  const mobileMenuItem = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <NavLink
        className={classes.link}
        style={{ textDecoration: "none" }}
        key={item.link}
        to={`/${item.link || "#"}`}
      >
        <Menu.Item className={classes.linkLabel} key={item.link}>
          {item.label}
        </Menu.Item>
      </NavLink>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="click"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <NavLink
              key={link.link}
              className={classes.link}
              style={{ textDecoration: "none" }}
              to={`/${link.link}`}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <ChevronDown size={15} />
              </Center>
            </NavLink>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <NavLink
        key={link.link}
        className={classes.link}
        style={{ textDecoration: "none" }}
        to={`/${link.link}`}
      >
        {link.label}
      </NavLink>
    );
  });

  const MobileMenu = () => (
    <Menu opened={opened} position="bottom-start" offset={5}>
      <Menu.Target>
        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
      </Menu.Target>
      <Menu.Dropdown>{mobileMenuItem}</Menu.Dropdown>
    </Menu>
  );

  const items = links.map((link) => {
    // const Icon = link?.icon;

    const menuItems = link.links?.map((item) => (
      <NavLink
        style={{ textDecoration: "none" }}
        key={item.link}
        to={`/${item.link}`}
      >
        <Menu.Item key={item.link}>{item.label}</Menu.Item>
      </NavLink>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <NavLink
              key={link.link}
              className={classes.link}
              style={{ textDecoration: "none" }}
              to={`/${link.link}`}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <ChevronDown size={15} />
              </Center>
            </NavLink>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <NavLink
        key={link.link}
        className={classes.link}
        style={{ textDecoration: "none" }}
        to={`/${link.link}`}
      >
        {link.label}
      </NavLink>
    );
  });

  return (
    <header className={classes.header}>
      <Container size="lg">
      <div className={classes.inner}>
        <Group>
          <NavLink style={{textDecoration:'none', color:'black'}} to="/"><Text size="lg">SHS Admissions </Text></NavLink>
        </Group>

        <Group
          align="flex-end"
          gap={15}
          className={classes.links}
          visibleFrom="sm"
        >
          {items}
        </Group>
        <Group hiddenFrom="sm">
          <MobileMenu />
        </Group>
        {/* <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" /> */}
      </div>
      </Container>
    </header>
  );
}
