import apiClient from './client'

/**
 * This enpoint is used to submit the student's biodata to the server
 * @param {*} values 
 * @returns 
 */
const submitStudentForm = (values) => {
    return apiClient.post('/student/bio-data', values)
}

/**
 * This enpoint is used to retrieve prospectus and biodata
 * supply the following parameters schoolId, jhsIndexNumber, voucherNumber
 * @param {*} values 
 * @returns 
 */
const getProspectus = (values)=>{
    return apiClient.get(`/student/prospectus?schoolId=${values.schoolId}&jhsIndexNumber=${values.jhsIndexNumber}&voucherNumber=${values.voucherNumber}`)
}

/**
 * This endpoint is used to download admission letter
 * supply the following params schoolId, jhsIndexNumber, voucherNumber
 */
const getAdmissionLetter = (values)=>{
    return apiClient.get(`/student/admission-letter/${values.schoolId}/${values.jhsIndexNumber}/${values.voucherNumber}`)
}


export{submitStudentForm, getProspectus, getAdmissionLetter}