import React from 'react'
import PageTitle from '../../components/header/PageTitle'
import { Card, Container, Flex, Stack, Text } from '@mantine/core'

function AboutUs() {
  return (
    <>
      <PageTitle title="About Us" />
      <Container size="lg" mt={50}>
        <Text ta="center" size="25px" fw="bold" mb={10}>
          About Us
        </Text>
        

        <Flex
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card >
            <Stack  align="center">
            <p>
              The fundamental reason behind the development of SHS Admissions is to ease the stress parents
and their wards go through just to get enrolment in the schools where they are placed.
Traditionally, when placements are released by The Computerised School Selection and
Placement System (CSSPS), parents of students who have gained placement must travel from
wherever they are to the schools in order to be enrolled, often at high transportation costs. Most
schools have set up teams that handle admissions year in year out. However, what typically
happens at these centres are long queues of parents waiting to be attended to. These things create
a lot of inconvenience for both the schools and the parents/students.
              </p>

              <p>
              SHS Admissions offer an <strong>OPTION</strong> where parents can just sit at the comfort of their homes and
enrol their wards in the schools they have been placed in. It must be noted this solution is just an
option and does not in any way replace the typical enrolment process that is done in the schools.
Parents or students who wish to visit the schools physically in order to be enrolled can do so as it
is entirely their choice.
              </p>

              <p>
              The SHS Admissions system has been designed to handle all cases of admissions including auto
placement, self placement, and protocols. There is no deliberate attempt from CrafterHive
Limited to limit student enrolments to SHS Admissions only. As mentioned earlier, it is just a
more convenient option for reducing stress, cost and time during admissions. Any attempt by
supported schools to force placed students to enrol via SHS Admissions only is solely borne of
the school and does reflect our policy for the system.
              </p>

            </Stack>
          </Card>
        </Flex>
      </Container>
    </>
  )
}

export default AboutUs