
import { create } from "apisauce";
import { getBaseUrl } from "../constants";

let url = getBaseUrl(process.env.NODE_ENV);
const apiClient = create({
    baseURL: url.BASE_URL
})

//add authentication token when present
apiClient.addAsyncRequestTransform(async (request)=>{
    
    //add content type to the requests
    request.headers['Content-Type'] = "application/json"; 
});

export default apiClient;