import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from '@reduxjs/toolkit/query';
import { shsAdmissionsApi } from "../api/rtk-query-apis";

export const store = configureStore({
    reducer:  {
        //add the generated reducer as a specific top-level slice
        [shsAdmissionsApi.reducerPath] : shsAdmissionsApi.reducer
    },

    middleware: (getDefaultMiddleware) => {
       return getDefaultMiddleware().concat(shsAdmissionsApi.middleware);
    },
});

setupListeners(store.dispatch);