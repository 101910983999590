import React, {  useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { DateInput,  } from '@mantine/dates';
import { useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import logger from '../../utils/logger';


function InputDate({ label, placeholder, name, size = "sm", defaultDate,  ...props }) {
  const { errors, touched, setFieldValue } = useFormikContext()
  const {fontSizes} = useMantineTheme();
  const [value, setValue] = useState(new Date())

  const onChange = (value) => {
    setValue(value)
    setFieldValue(name, dayjs(value).format("YYYY-MM-DD"))
  }

  useEffect(() => {
    logger("Default Date: ", defaultDate);
    logger("Date Value", value)
  }, [defaultDate, value]);

  useMemo(() => {
    setValue(new Date(defaultDate))
  }, [defaultDate])

  return (
    <DateInput
      value={value}
      defaultDate={dayjs(defaultDate)}
      valueformat='YYYY-MM-DD'
      onChange={onChange}
      placeholder={placeholder}
      inputformat="YYYY-MM-DD"
      labelformat="YYYY-MM-DD"
      label={label}
      size={size}
      mb={10}
      error={(errors[name] && touched[name]) ? errors[name] : false}
      errorProps={{ style: { fontSize: fontSizes.xs } }}
      {...props}
    />
  )
}

export default InputDate