import { Card, Container, Flex, Stack, Text } from '@mantine/core'
import React from 'react'
import PageTitle from '../../components/header/PageTitle'

function PrivacyPolicy() {
  return (
    <>
    <PageTitle title="Privacy Policy" />
    <Container size="lg" mt={50}>
      <Text ta="center" size="25px" fw="bold" mb={10}>
        Privacy Policy
      </Text>
      
      <Flex
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card >
          <Stack  align="center">

          </Stack>
        </Card>
      </Flex>
    </Container>
  </>
  )
}

export default PrivacyPolicy