import React from 'react'
// import iconLoading from '../../assets/loading-state.gif'
import {  Center, Flex, Image, MantineProvider, Loader } from '@mantine/core'
function LoadingComponent() {
  return (
    <MantineProvider withGlobalClasses withCssVariables>
      <Flex style={{
      minHeight: "95vh",
      backgroundColor: "#EEEEEE",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>
      <Center>
        {/* <Image src={iconLoading} width={100} height={100} alt='Loading' /> */}
        <Loader size="xl" />
      </Center>
    </Flex>
    </MantineProvider>
    
    
  )
}

export default LoadingComponent