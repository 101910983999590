import {
  Alert,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SchoolPicture from "../../components/school/SchoolPicture";
import { useMediaQuery } from "@mantine/hooks";
import { sessionGetIsSubdomain } from "../../store/local/sessionStore";
import { InputSelect, InputText } from "../../components/input";
import { useModals } from "@mantine/modals";
import * as Yup from "yup";
import * as localStore from "../../store/local/localStore";
import * as apiSchool from "../../api/school";
import iconVoucher from "../../assets/voucher.png";
import { Link } from "react-router-dom";
import { Routes as routes } from "../../constants";
import { notifications } from "@mantine/notifications";
// import * as apiVoucher from "../../api/voucher";
import { buyVoucher } from "@Crafterhive/cssps";
import SchoolPictureCarousel from "../../components/school/SchoolPictureCarousel";
import PageTitle from "../../components/header/PageTitle";
import logger from "../../utils/logger";
import { useFetchPublishedSchoolsQuery } from "../../api/rtk-query-apis";

const validationSchema = Yup.object().shape({
  schoolId: Yup.string().required().label("School Placed"),
  jhsIndexNumber: Yup.string()
    .required()
    .min(12)
    .max(12)
    .label("JHS Index Number"),
  phoneNumber: Yup.string().required().min(10).max(10).label("Phone Number"),
});
function BuyVoucher() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const modals = useModals();

  //fetch published schools
  const {data=[], isFetching, refetch:refetchPublishedSchools  } = useFetchPublishedSchoolsQuery();

  //set initial button state
  const [loading, setLoading] = useState(false);
  const isSubdomain = JSON.parse(sessionGetIsSubdomain());
  const [publishedSchools, setPublishedSchools] = useState([]);
  const [school, setSchool] = useState(
    localStore.getSchoolDetails() || undefined
  );
  const [selectSchoolList, setSelectSchoolList] = useState([]);
  const isLoaded = useRef(false);
  //set default state values for buy voucher fields
  const [buyVoucherVal, setBuyVoucherVal] = useState({
    jhsIndexNumber: "",
    phoneNumber: "",
    schoolId: "",
    schoolCode: "",
  });

  const getPublishedSchools = useCallback(async () => {

    setPublishedSchools(data);
    let schoolList = [];
    data?.forEach((school) => {
      schoolList.push({ value: "" + school.id, label: school.name });
    });
    // logger("select school ", selectSchoolList);
    setSelectSchoolList(schoolList);
  }, [data]);

  const showConfirmStudentDetailsDialog = ({
    studentName,
    school,
    checkoutUrl,
    phoneNumber,
  }) => {
    modals.openConfirmModal({
      title: "Confirm Student",
      closeOnClickOutside:false,
      children: (
        <>
          Student Name: <strong>{studentName}</strong> <br />
          School: <strong>{school}</strong> <br />
          <br />
          The voucher code will be sent to <strong>{phoneNumber}</strong>. Do
          you want to continue?
        </>
      ),
      labels: { confirm: "Yes", cancel: "Cancel" },
      onConfirm: () => {
        //redirect to purchase voucher
        window.location = checkoutUrl;
      },
    });
  };

  //submit data to server method
  const submitToServer = async () => {
    try {
      //assigns the callbackurl to a variable
      //the callback url is the url used to verify/confirm the voucher payment
      const callbackUrl = localStore.getConfirmVoucherCallback();
      let school = publishedSchools.find(
        (value, index) => value.id === parseInt(buyVoucherVal.schoolId)
      );
      logger("found school ", school);
      logger("published school", publishedSchools);
      const val = {
        schoolId: buyVoucherVal.schoolId,
        jhsIndexNumber: buyVoucherVal.jhsIndexNumber,
        phoneNumber: `233${buyVoucherVal.phoneNumber.substring(1, 10)}`,
        callbackUrl: callbackUrl,
        schoolCode: school.schoolCode,
      };

      logger("buyvoucher", val);
      setLoading(true);
      const response = await buyVoucher(val);
      setLoading(false);

      //save jhsIndexNumber and schoolID in localStorage
      const respData = {
        jhsIndexNumber: buyVoucherVal.jhsIndexNumber,
        reference: response.reference,
      };

      logger("buy voucher response ", response);
      logger("buy voucher response two", JSON.stringify(response));
      //store the reference number and index number to the localStorage,
      //this will be used to verify/confirm the voucher purchase
      //when the user is redirected to our callbackUrl from the external payment checkout page
      // localStore("user", JSON.stringify(respData));
      localStore.storeStudentVoucherDetails(respData);

      // return;
      //show student preview dialog before redirecting
      showConfirmStudentDetailsDialog({
        studentName: response?.student?.name,
        school: school?.name,
        checkoutUrl: response.checkoutUrl,
        phoneNumber: buyVoucherVal.phoneNumber,
      });
    } catch (error) {
      setLoading(false);

      logger(error);
      notifications.show({
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Unexpected Error",
        color: "red",
      });
    }
  };

  //this method handles the onclick event of the submit button
  const handleDataSubmit = (handleSubmit, values, setValues) => {
    //set the values in the state variable to the input field values
    //call the default handleSubmit function to validate input fields using the validation schema
    setValues(buyVoucherVal);
    setBuyVoucherVal({
      schoolId: values.schoolId,
      jhsIndexNumber: values.jhsIndexNumber,
      phoneNumber: values.phoneNumber,
    });
    handleSubmit();
  };
  

  //onSubmit method calls the api to submit field values to the server
  const onSubmit = async (values) => {
    // openConfirmModal(values, handleOnDialogSubmit);
    submitToServer();
  };

  /**
   * When submit button is clicked, this method runs first,
   * Therefore, update the data in the state variable,
   * Then validate the values inside the handleDataSubmit function
   * @param {*} values
   */
  const validate = (values) => {
    //if school data is not in session, take from the
    // logger("school data", schoolData);
    logger("values ", values);
    logger("school", school);
    if (school) {
      setBuyVoucherVal({
        jhsIndexNumber: values.jhsIndexNumber,
        schoolId: school.school.id,
        phoneNumber: values.phoneNumber,
      });
    } else {
      setBuyVoucherVal({ ...values });
    }
  };

  useEffect(() => {
    if(!isFetching && data){
      logger("bv fetching", data)
      getPublishedSchools();
    }
  }, [data, getPublishedSchools, isFetching]);

  return (
    <div>
      <PageTitle title="Buy Voucher" />
      <Formik
        initialValues={buyVoucherVal}
        validate={validate}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, setValues, values }) => (
          <>
            <SimpleGrid
              cols={{sm: 2, xs: 1, md: 2, lg: 2, xl: 2 }}
              spacing="lg"
            >
              {!isSmallScreen && (
                <Flex
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  bg="#EEEEEE"
                  h="95vh"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <Grid justify="center" mt="10%">
                    <Grid.Col>
                      {/* <SchoolPicture /> */}
                      <SchoolPictureCarousel />
                    </Grid.Col>
                  </Grid>
                </Flex>
              )}
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Card>
                  <Center>
                    <Image
                      width={100}
                      height={100}
                      fit="contain"
                      src={
                        school && isSubdomain === true
                          ? school?.school?.logoUrl
                          : iconVoucher
                      }
                      alt="School logo"
                    />
                  </Center>
                  <Grid justify="center" mt={5}>
                    <Grid.Col
                      span={{ lg: 8, md: 8, sm: 12, xs: 12 }}
                      className="flex-center"
                    >
                      <Alert>
                        <ul>
                          {!school && (
                            <li>
                              Select the exact school you're placed from the
                              list below.
                            </li>
                          )}
                          <li>
                            Enter your 10 digits Index Number followed by the{" "}
                            <strong>year. eg. 100000024</strong>{" "}
                          </li>
                          <li>Enter a valid phone number to buy a voucher</li>
                        </ul>
                      </Alert>
                      {!isSubdomain && (
                        <InputSelect
                          mt={10}
                          size="lg"
                          label="Select School Placed"
                          name="schoolId"
                          placeholder="Select"
                          data={selectSchoolList}
                          defaultalue={`${buyVoucherVal.schoolId}`}
                        />
                      )}
                      <InputText
                        mt={10}
                        size="lg"
                        label="JHS Index Number"
                        name="jhsIndexNumber"
                        placeholder="10000000024"
                        maxLength={12}
                        defaultalue={buyVoucherVal.jhsIndexNumber}
                      />

                      <InputText
                        size="lg"
                        label="Phone Number"
                        name="phoneNumber"
                        placeholder="eg. 0240000000"
                        maxLength={10}
                        defaultalue={buyVoucherVal.phoneNumber}
                      />

                      <Button
                        size="lg"
                        mt={30}
                        type="button"
                        fullWidth
                        loading={loading}
                        variant="filled"
                        onClick={() =>
                          handleDataSubmit(handleSubmit, values, setValues)
                        }
                      >
                        Buy Voucher
                      </Button>
                      <Text align="center" mt={20}>
                        <strong>
                          <Link to={"/" + routes.ROUTE_RETRIEVE_VOUCHER}>
                            Retrieve Lost Voucher
                          </Link>
                        </strong>
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Flex>
            </SimpleGrid>
            {/* </Flex> */}
          </>
        )}
      </Formik>
      {/* </Container> */}
    </div>
  );
}

export default BuyVoucher;
