import { useCallback, useEffect, useState } from "react";
import * as localStore from "./store/local/localStore";
import { Routes as AppRoutes } from "./constants";
import { sessionSetIsSubdomain } from "./store/local/sessionStore";
import * as apiSchool from "./api/school";
import AppDashboard from "./AppDashboard";
import NotFound from "./views/NotFound";
import LoadingComponent from "./components/loaders/LoadingComponent";
import logger from "./utils/logger";
import UnpublishedSchoolNotice from "./views/UnpublishedSchoolNotice";

function App() {
  const [message, setMessage] = useState("");
  const [school, setSchoolDetails] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [isSchoolFound, setSchoolFound] = useState(false); //sets if a school is found or if user is on a domain/subdoman

  /**
   * This method checks if a user is accessing the domain from a subdomain/school nickname.
   * eg. sogasco.shsadmissions.com
   * This method handles edge cases such as www.sogasco.shsadmissions.com or sogasco.shsadmissions.com
   */
  const checkSubdomain = useCallback(async () => {
    let url = "";
    let subdomain = "";

    if (process.env.NODE_ENV === "development") {
      url = "https://shsadmissions.com";
      let callback = `${window.location.origin}/${AppRoutes.ROUTE_CONFIRM_VOUCHER}`;
      logger("callback ", callback);
      localStore.storeConfirmVoucherCallback(callback);
    } else {
      //get the domain the user is on and append confirm voucher endpoint to it
      //store the full url in a local storage
      //this is used as a callback url when a user buys a voucher and it's being verified
      url = window.location.hostname;

      let callback = `${window.location.origin}/${AppRoutes.ROUTE_CONFIRM_VOUCHER}`;
      logger("callback ", callback);
      localStore.storeConfirmVoucherCallback(callback);
    }

    subdomain = getSubdomain(url);
    if (!subdomain) {
      //this is on the main domain but to subdomain
      logger("is subdomain ", subdomain);
      sessionSetIsSubdomain(false);
      //school found is true and the generic page will be displayed since user accessed the main domain
      setSchoolFound(true);
      setLoading(false);
      return;
    }

    //its a subdomain so lets get the school's details and store it
    const response = await apiSchool.fetchSchool(subdomain);
    logger("school response ", response.data);
    if (!response.ok) {
      //if fetching school details fail, show page not found message.
      setSchoolFound(false);
      setLoading(false);
      setMessage(response.data.message);
      return;
    }

    //if school is found save in session/local storage
    sessionSetIsSubdomain(true); //this will toggle the display of the school's customized page
    localStore.storeSchoolDetails(response?.data);
    setLoading(false);
    setSchoolFound(true); //set school to be found and show the school's subdomain
    setSchoolDetails(response?.data?.school);
  }, []);

  /**
   * This function returns a subdomain when a hostname is supplied or null if there is no subdomain
   * @param {*} url a hostname url. eg. `sogasco.shsadmissions.com`
   * @returns `sogasco` if a subdomain is found otherwise null
   */
  const getSubdomain = (url) => {
    let domain = url.split(".");
    if (domain.includes("www") && domain.length === 4) {
      //example if url is www.sogasco.shsadmissions.com
      //subdomain we are looking for is sogasco
      return domain[1];
    } else if (!domain.includes("www") && domain.length === 3) {
      //if the domains does not include www and its length is 3, its main domain
      //taking the first part returns https://subdomain. so remove the https:// part and take the subdomain
      return domain[0].substring(
        domain[0].lastIndexOf("/") + 1,
        domain[0].length
      );
    }

    //if null is returned, then its a main domain
    return null;
  };

  useEffect(() => {
    checkSubdomain();
  }, [checkSubdomain]);

  //show components based on the state
  if (isLoading) return <LoadingComponent />;
  else if (school?.isPublished === false)
    return <UnpublishedSchoolNotice school={school} />;
  else if (isSchoolFound) return <AppDashboard />;
  else return <NotFound message={message} />;
}

export default App;
