import React, { useState } from "react";
import PageTitle from "../../components/header/PageTitle";
import {
  Card,
  Container,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { PhoneCall } from "tabler-icons-react";
import * as localStore from "../../store/local/localStore";
import * as sessionStore from "../../store/local/sessionStore";

function ContactUs() {
  const school = localStore.getSchoolDetails();
  const isSubdomain = JSON.parse(sessionStore.sessionGetIsSubdomain());
  const colsAlign = isSubdomain
    ? { base: 1, xl: 2, lg: 2, md: 2, xs: 1, sm: 1 }
    : { base: 1, xl: 1, lg: 1, md: 1, xs: 1, sm: 1 };

  return (
    <>
      <PageTitle title="Contact Us" />
      <Container size="md" mt={50} >
        <Text ta="center" size="25px" fw="bold" mb={10}>
          SHS Admissions
        </Text>

        <Flex
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SimpleGrid cols={colsAlign} spacing="lg">
            {isSubdomain && school?.school?.nickName === "adasco" && (
              <Card withBorder shadow="sm">
                <Stack align="center">
                  <Text fw="bold">School Enquiries</Text>

                  <Image
                    src={school?.school?.logoUrl}
                    style={{ width: "50px", height: "50px" }}
                    fit="contain"
                  />

                  <Text size="lg">{school?.school?.name}</Text>
                  <Text>
                    Phone:{" "}
                    <a
                      href={`tel:${
                        school?.school?.phoneNumber || "0244855993"
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      {school?.school?.phoneNumber || "0244855993"}
                    </a>{" "}
                    /{" "}
                    <a
                      href={`tel:${
                        school?.school?.phoneNumber || "0249150621"
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      {school?.school?.phoneNumber || "0249150621"}
                    </a>
                  </Text>
                </Stack>
              </Card>
            )}
            <Card withBorder shadow="sm">
              <Stack align="center">
                <Text fw="bold">Technical Support</Text>
                <Text ta="center">
                  Want to get in touch and work with us or experiencing issues
                  on the site?{" "}
                </Text>
                <PhoneCall size={50} />
                <Title order={5}>Call Us</Title>
                <Text>
                  Phone: {" "}
                  <a href="tel:0249640111" style={{ textDecoration: "none" }}>
                    0249640111
                  </a>{" "}
                  /{" "}
                  <a href="tel:0546869171" style={{ textDecoration: "none" }}>
                    0546869171
                  </a>
                </Text>
              </Stack>
            </Card>
          </SimpleGrid>
        </Flex>
      </Container>
    </>
  );
}

export default ContactUs;
