import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Table,
  Title,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputDate, InputSelect, InputText } from "../../components/input";
import { Formik } from "formik";
import * as Yup from "yup";
import * as apiVoucher from "../../api/voucher";
import * as localStore from "../../store/local/localStore";
import * as selectionData from "../../data/data";
import countries from "../../data/countries";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import logger from "../../utils/logger";

const validationSchema = Yup.object().shape({
  region: Yup.string().required().label("Region"),
  religiousDenomination: Yup.string()
    .required()
    .label("Religious Denomination"),
  physicalChallenge: Yup.string().required().label("Physical Challenge"),
  dateOfBirth: Yup.date().required().label("Date of Birth"),
  placeOfBirth: Yup.string().required().label("Place of Birth"),
  hometown: Yup.string().required().label("Hometown"),
  nationality: Yup.string().required().label("Nationality"),
  address: Yup.string().required().label("Address"),
  phoneNumber: Yup.string().required().max(10).label("Phone Number"),
  lastSchoolAttended: Yup.string()
    .required()
    .label("Name of Last School Attended"),
  addressOfLastSchoolAttended: Yup.string()
    .required()
    .label("Address of Last School Attended"),
  nameOfFather: Yup.string().required().label("Name of Father"),
  occupationOfFather: Yup.string().required().label("Occupation of Father"),
  addressOfFather: Yup.string().required().label("Address of Father"),
  phoneNumberOfFather: Yup.string()
    .required()
    .max(10)
    .label("Phone Number of Father"),
  nameOfMother: Yup.string().required().label("Name of Mother"),
  occupationOfMother: Yup.string().required().label("Occupation of Mother"),
  addressOfMother: Yup.string().required().label("Address of Mother"),
  phoneNumberOfMother: Yup.string()
    .required()
    .max(10)
    .label("Phone Number of Mother"),
  nameOfGuardian: Yup.string().required().label("Name of Guardian"),
  occupationOfGuardian: Yup.string().required().label("Occupation of Guardian"),
  phoneNumberOfGuardian: Yup.string().max(10).label("Phone Number of Guardian"),
  postalAddressOfGuardian: Yup.string()
    .required()
    .label("Postal Address of Guardian"),
  residentialAddressOfGuardian: Yup.string()
    .required()
    .label("Residential Address of Guardian"),
  enrolmentCode: Yup.string().required().label("Enrolment Code"),
  guardian: Yup.string().required().label("Guardian"),
});

function StepOneAdmissionFormFill({
  admiData,
  student,
  onSubmit,
  onNextClick,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)"); // Adjust breakpoint as needed
  const navigate = useNavigate();
  const [userVoucherDetails, setUserVoucherDetails] = useState(
    localStore.getStudentVoucherDetails()
  );
  const [school, setSchool] = useState(localStore.getSchoolDetails());
  const [admissionData, setAdmissionData] = useState(admiData);
  const verifyVoucherRef = useRef(false);
  const isLoaded = useRef(false);

  const onCloseClicked = () => {
    modals.openConfirmModal({
      title: "Close",
      children: "Unsaved changes will be lost",
      labels: { cancel: "Cancel", confirm: "Close" },
      onConfirm: () => {
        navigate(-1);
      },
    });
  };

  const handleGuardian = (values) => {
    let guardianValue = {};
    if (values.guardian === "Father") {
      guardianValue = {
        nameOfGuardian: values.nameOfFather,
        occupationOfGuardian: values.occupationOfFather,
        phoneNumberOfGuardian: values.phoneNumberOfFather,
        postalAddressOfGuardian: values.addressOfFather,
        residentialAddressOfGuardian: values.addressOfFather,
      };
    } else if (values.guardian === "Mother") {
      guardianValue = {
        nameOfGuardian: values.nameOfMother,
        occupationOfGuardian: values.occupationOfMother,
        phoneNumberOfGuardian: values.phoneNumberOfMother,
        postalAddressOfGuardian: values.addressOfMother,
        residentialAddressOfGuardian: values.addressOfMother,
      };
    } else {
      guardianValue = {};
    }

    return guardianValue;
  };

  const onSubmitClick = (
    handleSubmit,
    setFieldValue,
    values,
    setValues,
    errors
  ) => {
    logger("errors", errors);
    logger("values", values);

    // logger("student submit data, ", student);
    // logger("admi submit data", admiData)
    setAdmissionData((prev) => ({
      ...admiData,
      ...values,
      ...handleGuardian(values),
    }));

    logger("admission data", admissionData);
    // logger("admission guardian", guardianData);

    handleSubmit();
  };

  //this method verifies user's voucher code and indexNumber when this page is opened
  const verifyUser = useCallback(async () => {
    logger("user", userVoucherDetails);
    if (!userVoucherDetails) {
      navigate("/");
      return;
    }

    const response = await apiVoucher.verifyVoucher({
      jhsIndexNumber: userVoucherDetails.jhsIndexNumber,
      voucherNumber: userVoucherDetails.voucherNumber,
    });
    if (!response.ok) {
      navigate("/");
    }

    verifyVoucherRef.current = true; //set the method run to true
  }, [navigate, userVoucherDetails]);

  useEffect(() => {
    // logger("admi data", admissionData)
    if (!verifyVoucherRef.current && !isLoaded.current) {
      setAdmissionData(admiData);
      verifyUser();
      verifyVoucherRef.current = true;
      isLoaded.current = true;
    }
  }, [admiData, verifyUser]);

  const validate = (values) => {
    if (values.guardian === "Father") {
      values.nameOfGuardian = values.nameOfFather;
      values.occupationOfGuardian = values.occupationOfFather;
      values.phoneNumberOfGuardian = values.phoneNumberOfFather;
      values.postalAddressOfGuardian = values.addressOfFather;
      values.residentialAddressOfGuardian = values.addressOfFather;
    } else if (values.guardian === "Mother") {
      values.nameOfGuardian = values.nameOfMother;
      values.occupationOfGuardian = values.occupationOfMother;
      values.phoneNumberOfGuardian = values.phoneNumberOfMother;
      values.postalAddressOfGuardian = values.addressOfMother;
      values.residentialAddressOfGuardian = values.addressOfMother;
    }
  };

  return (
    <>
      <Formik
        onSubmit={() => onSubmit(admissionData)}
        initialValues={admiData}
        enableReinitialize={true}
        validate={validate}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, setFieldValue, values, setValues, errors }) => (
          <>
            <Container>
              <Divider />
              <Table mt={10} striped highlightOnHover verticalSpacing="md" withTableBorder>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      Name: <strong> {student?.name}</strong>
                    </Table.Td>

                    <Table.Td>
                      Program Offered:{" "}
                      <strong> {student?.programOffered}</strong>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      Index Number: <strong>{admiData?.jhsIndexNumber}</strong>
                    </Table.Td>
                    <Table.Td>
                      Track: <strong>{student?.track}</strong>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>

              <div>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      marginBottom: 2,
                      width: "100%",
                      alignItems: "stretch",
                    },
                  }}
                  noValidate
                  autoComplete="of"
                  autoSave="on"
                >
                  <Title mt={10} order={3}>
                    Student Biodata
                  </Title>
                  <Grid>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                      <InputText
                        label="Enrolment Code"
                        name="enrolmentCode"
                        placeholder="eg. 12345678"
                        defaultValue={admiData.enrolmentCode}
                        required
                      />
                      <InputText
                        label="Place of Birth"
                        name="placeOfBirth"
                        placeholder="eg. Accra"
                        defaultValue={admiData.placeOfBirth}
                      />
                      <InputText
                        label="Hometown"
                        name="hometown"
                        placeholder="eg. Accra"
                        defaultValue={admiData.hometown}
                      />
                      <InputSelect
                        label="Nationality"
                        name="nationality"
                        placeholder="Select"
                        data={countries}
                        searchable
                        defaultValue={admiData.nationality}
                      />
                      <InputSelect
                        label="Region"
                        name="region"
                        placeholder="Select"
                        data={selectionData.regions}
                        defaultValue={admiData.region}
                      />
                      <InputText
                        label="Religious Denomination"
                        name="religiousDenomination"
                        placeholder="eg. Christian"
                        defaultValue={admiData.religiousDenomination}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                      <InputDate
                        label="Date of Birth"
                        name="dateOfBirth"
                        defaultDate={admiData.dateOfBirth}
                      />
                      <InputText
                        label="Address"
                        name="address"
                        placeholder="eg. Address"
                        defaultValue={admiData.address}
                      />
                      <InputText
                        label="Phone Number"
                        name="phoneNumber"
                        placeholder="eg. 0240000000"
                        defaultValue={admiData.phoneNumber}
                        maxLength={10}
                        inputMode="tel"
                      />
                      <InputSelect
                        label="Physical Challenge"
                        name="physicalChallenge"
                        placeholder="Select"
                        defaultValue={admiData.physicalChallenge}
                        data={selectionData.physicalChallenge}
                      />
                      <InputText
                        label="Name of Last School Attended"
                        name="lastSchoolAttended"
                        placeholder="eg. D/A JHS"
                        defaultValue={admiData.lastSchoolAttended}
                      />
                      <InputText
                        label="Address of Last School Attended"
                        name="addressOfLastSchoolAttended"
                        placeholder="eg. Address"
                        defaultValue={admiData.addressOfLastSchoolAttended}
                      />
                    </Grid.Col>
                  </Grid>

                  {/* PARENT INFORMATION */}
                  <Title mt={10} order={3}>
                    Parent Information
                  </Title>
                  <Grid>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                      <InputText
                        label="Name of Father"
                        name="nameOfFather"
                        placeholder="eg. John Doe"
                        defaultValue={admiData.nameOfFather}
                      />
                      <InputText
                        label="Occupation of Father"
                        name="occupationOfFather"
                        placeholder="eg. Businessman"
                        defaultValue={admiData.occupationOfFather}
                      />
                      <InputText
                        label="Address of Father"
                        name="addressOfFather"
                        placeholder="eg. Address"
                        defaultValue={admiData.addressOfFather}
                      />
                      <InputText
                        label="Phone Number of father"
                        name="phoneNumberOfFather"
                        placeholder="eg. 0240000000"
                        maxLength={10}
                        defaultValue={admiData.phoneNumberOfFather}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                      <InputText
                        label="Name of Mother"
                        name="nameOfMother"
                        placeholder="eg. Mary Doe"
                        defaultValue={admiData.nameOfMother}
                      />
                      <InputText
                        label="Occupation of Mother"
                        name="occupationOfMother"
                        placeholder="eg. Businesswoman"
                        defaultValue={admiData.occupationOfMother}
                      />
                      <InputText
                        label="Address of Mother"
                        name="addressOfMother"
                        placeholder="eg. Address"
                        defaultValue={admiData.addressOfMother}
                      />
                      <InputText
                        label="Phone Number of Mother"
                        name="phoneNumberOfMother"
                        placeholder="eg. 0240000000"
                        maxLength={10}
                        defaultValue={admiData.phoneNumberOfMother}
                      />
                    </Grid.Col>
                  </Grid>

                  {/* Guardian Information */}
                  <Title mt={10} order={3}>
                    Guardian Information
                  </Title>

                  <Grid>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
                      {/* <Select
                          label="Guardian"
                          name="guardian"
                          placeholder="Select"
                          data={constData.guardian}
                          defaultValue={admiData.guardian}
                          onChange={(guardian) => handleGuardianChange(guardian, values)}
                        /> */}
                      <InputSelect
                        label="Guardian"
                        name="guardian"
                        placeholder="Select"
                        data={selectionData.guardian}
                        defaultValue={admiData.guardian}
                      />

                      {
                        //if the guardian selected is other than Mother/Father, display fields to fill
                        values.guardian === "Other" && (
                          <>
                            <InputText
                              label="Name of Guardian"
                              name="nameOfGuardian"
                              placeholder="eg. John Mary"
                              defaultValue={admiData.nameOfGuardian}
                            />
                            <InputText
                              label="Occupation of Guardian"
                              name="occupationOfGuardian"
                              placeholder="eg. Businessman"
                              defaultValue={admiData.occupationOfGuardian}
                            />
                          </>
                        )
                      }
                    </Grid.Col>
                    {
                      //if the guardian selected is other than Mother/Father, display fields to fill
                      values.guardian === "Other" && (
                        <>
                          <Grid.Col
                            span={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}
                          >
                            <InputText
                              label="Phone Number of Guardian"
                              name="phoneNumberOfGuardian"
                              placeholder="eg. 0240000000"
                              maxLength={10}
                              defaultValue={admiData.phoneNumberOfGuardian}
                            />
                            <InputText
                              label="Residential Address of Guardian"
                              name="residentialAddressOfGuardian"
                              placeholder="eg. Hse No. 123"
                              defaultValue={
                                admiData.residentialAddressOfGuardian
                              }
                            />
                            <InputText
                              label="Postal Address of Guardian"
                              name="postalAddressOfGuardian"
                              placeholder="eg. Address"
                              defaultValue={admiData.postalAddressOfGuardian}
                            />
                          </Grid.Col>
                        </>
                      )
                    }
                  </Grid>

                  <Group mt={30} mb={30} justify="space-between" align="center">
                    {isMobile ? (
                      <>
                        <Button
                          w={400}
                          size="lg"
                          type="button"
                          variant="filled"
                          onClick={() =>
                            onSubmitClick(
                              handleSubmit,
                              setFieldValue,
                              values,
                              setValues,
                              errors
                            )
                          }
                        >
                          Continue
                        </Button>
                        <Button
                          variant="outline"
                          size="lg"
                          onClick={onCloseClicked}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outline"
                          size="lg"
                          onClick={onCloseClicked}
                        >
                          Close
                        </Button>
                        <Button
                          w={400}
                          size="lg"
                          type="button"
                          variant="filled"
                          onClick={() =>
                            onSubmitClick(
                              handleSubmit,
                              setFieldValue,
                              values,
                              setValues,
                              errors
                            )
                          }
                        >
                          Continue
                        </Button>
                      </>
                    )}
                  </Group>
                </Box>
              </div>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
}

export default StepOneAdmissionFormFill;
