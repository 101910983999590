import React, { useEffect,  useState } from 'react'
import { useFormikContext } from 'formik'
import { Select, useMantineTheme } from '@mantine/core';

function InputSelect({ label, placeholder, name, size = "sm", data = [], defaultValue, ...props }) {
  const { errors, touched,  setFieldValue } = useFormikContext()
  const [value, setValue] = useState("")
  const {fontSizes}  = useMantineTheme();

  const onChange = (value) => {
    setValue(value)
    setFieldValue(name, value)
  }

  useEffect(() => {
    //set field value
      //set default value for selected option
      setValue(defaultValue)
   
  }, [defaultValue])



  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      size={size}
      data={data}
      mb={10}
      error={(errors[name] && touched[name]) ? errors[name] : false}
      errorProps={{ style: { fontSize: fontSizes.xs } }}
      clearable
      {...props}
    />
  )

}

export default InputSelect