import React, { useEffect } from 'react'
import PageTitle from '../components/header/PageTitle'
import { sessionGetIsSubdomain } from '../store/local/sessionStore'
import SchoolPage from './main/SchoolPage'
import GenericPage from './main/GenericPage'
import * as localStore from '../store/local/localStore'
import { useNavigate } from 'react-router-dom'
import { Routes as routes} from '../constants'
import logger from '../utils/logger'

function WelcomeScreen() {
    const navigate = useNavigate();

    const handleGetStaredClick = () => {
        navigate(routes.ROUTE_BUY_VOUCHER);
      };

      useEffect(()=> {
        logger("subdomain ", sessionGetIsSubdomain())
      }, [])

    // const swapComponentOnSchool = () => {
    //     const school = localStore.getSchoolDetails();
    
    //     logger("school", school);
    //     if (!sessionGetIsSubdomain()) {
    //       setSchool(false);
    //     } else {
    //       setSchool(true);
    //     }
    //   };
    
    //   useEffect(() => {
    //     swapComponentOnSchool();
    //   }, []);
  return (
    <>
        <PageTitle title="SHS Admissions"/>
        {sessionGetIsSubdomain() === "true" ? <SchoolPage handleGetStaredClick={handleGetStaredClick} /> : <GenericPage handleGetStaredClick={handleGetStaredClick} />}
    </>
  )
}

export default WelcomeScreen