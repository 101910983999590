import {
  Alert,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { sessionGetIsSubdomain } from "../../store/local/sessionStore";
import * as localStore from "../../store/local/localStore";
import * as apiSchool from "../../api/school";
import iconVoucher from "../../assets/voucher.png";
import { Routes as routes } from "../../constants";
import { notifications } from "@mantine/notifications";
import * as apiVoucher from "../../api/voucher";
import { InputSelect, InputText } from "../../components/input";
import SchoolPictureCarousel from "../../components/school/SchoolPictureCarousel";
import PageTitle from "../../components/header/PageTitle";
import logger from "../../utils/logger";
import { useFetchPublishedSchoolsQuery } from "../../api/rtk-query-apis";

const validationSchema = Yup.object().shape({
  schoolId: Yup.string().required().label("School Placed"),
  jhsIndexNumber: Yup.string().required().label("JHS Index Number"),
  // phoneNumber: Yup.string().required().min(10).max(10).label('Phone Number')
});
function RetrieveVoucher() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  //fetch published schools
  const {
    data = [],
    isFetching,
    refetch: refetchPublishedSchools,
  } = useFetchPublishedSchoolsQuery();

  const [loading, setLoading] = useState(false);
  const isSubdomain = JSON.parse(sessionGetIsSubdomain());
  const [publishedSchools, setPublishedSchools] = useState([]);
  const [school, setSchool] = useState(
    localStore.getSchoolDetails() || undefined
  );
  const [selectSchoolList, setSelectSchoolList] = useState([]);
  //set default state values for buy voucher fields
  const [retrieveVoucherVal, setretrieveVoucherVal] = useState({
    jhsIndexNumber: "",
    schoolId: "",
  });
  const isLoaded = useRef(false);

  //this method handles the onclick event of the submit button
  const handleDataSubmit = (handleSubmit, values, setValues) => {
    //set the values in the state variable to the input field values
    //call the default handleSubmit function to validate input fields using the validation schema
    // setValues(retrieveVoucherVal)
    setretrieveVoucherVal({
      schoolId: values.schoolId,
      phoneNumber: values.phoneNumber,
      jhsIndexNumber: values.jhsIndexNumber,
    });
    handleSubmit();
  };

  const getPublishedSchools = useCallback(async () => {
    setPublishedSchools(data);
    let schoolList = [];
    data?.forEach((school) => {
      schoolList.push({ value: "" + school.id, label: school.name });
    });
    // logger("select school ", selectSchoolList);
    setSelectSchoolList(schoolList);
  }, [data]);

  const showRetrievedVouchers = useCallback((voucherNumbers) => {
    function navigateBack(modals) {
      modals.closeAll();
    }
    modals.open({
      title: "Vouchers",
      closeOnClickOutside: false,
      onClose: () => {
        navigateBack(modals);
      },
      children: (
        <>
          {voucherNumbers}
          <Button
            mt={20}
            fullWidth
            onClick={() => {
              navigateBack(modals);
            }}
          >
            Close
          </Button>
        </>
      ),
    });
  }, []);

  //onSubmit method calls the api to submit field values to the server
  const onSubmit = async (values) => {
    setLoading(true);
    const val = {
      schoolId: values.schoolId,
      jhsIndexNumber: values.jhsIndexNumber,
    };
    const response = await apiVoucher.retrieveVoucher(val);
    setLoading(false);

    logger("voucher response", response);

    //if response is not successful, display error message in snackbar
    if (!response.ok) {
      notifications.show({
        title: "Error",
        message:
          response.status === 400 ? "Voucher Not Found" : response.data.message,
        color: "red",
      });
      return;
    }

    //render the list of voucher numbers in this template
    const voucherNumbers = (
      <div>
        Vouchers associated with <strong>{values.jhsIndexNumber}</strong>:{" "}
        <strong>
          <ul>
            {response?.data?.map((value, key) => (
              <>
                <li key={key}>{value}</li>
              </>
            ))}
          </ul>
        </strong>
      </div>
    );

    showRetrievedVouchers(voucherNumbers);

    //set voucher number to the dialog
    // setDialogMessage(voucherNumbers)
    // setDialogOpened(true)
  };

  /**
   * When submit button is clicked, this method runs first,
   * It sets the data from the input fields and schoolId to the voucherVal state
   * Then the handleDataSubmit funciton is called next to validate the values inside the handleDataSubmit function
   * @param {*} values
   */
  const validate = (values) => {
    //if school data is not stored in session, use the school ID from from select box
    logger("school data", school);
    if (school) {
      setretrieveVoucherVal({
        jhsIndexNumber: values.jhsIndexNumber,
        schoolId: school?.school.id,
        phoneNumber: values.phoneNumber,
      });
    } else {
      setretrieveVoucherVal({ ...values });
    }
    logger("validate values ", values);
  };

  useEffect(() => {
    if (!isFetching && data) {
      logger("fetching no", data)
      getPublishedSchools();
    }
  }, [data, getPublishedSchools, isFetching]);

  return (
    <div>
      <PageTitle title="Retrieve Voucher" />
      <Formik
        initialValues={retrieveVoucherVal}
        validate={validate}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, setValues, values }) => (
          <>
            <SimpleGrid
              cols={{ sm: 2, xs: 1, md: 2, lg: 2, xl: 2 }}
              spacing="lg"
            >
              {!isSmallScreen && (
                <Flex
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  bg="#EEEEEE"
                  h="95vh"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <Grid justify="center" mt="10%">
                    <Grid.Col>
                      <SchoolPictureCarousel />
                    </Grid.Col>
                  </Grid>
                </Flex>
              )}
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Card>
                  <Center>
                    <Image
                      width={100}
                      height={100}
                      fit="contain"
                      src={
                        school && isSubdomain === true
                          ? school?.school?.logoUrl
                          : iconVoucher
                      }
                      alt="School logo"
                    />
                  </Center>
                  <Grid justify="center" mt={5}>
                    <Grid.Col
                      span={{ lg: 8, md: 8, sm: 12, xs: 12 }}
                      className="flex-center"
                    >
                      <Text ta="center" size="lg">
                        {" "}
                        Retrieve Voucher Numbers
                      </Text>
                      <Alert>
                        <ul>
                          {!school && (
                            <li>
                              Select the exact school you're placed from the
                              list below.
                            </li>
                          )}
                          <li>
                            Enter your 10 digits Index Number followed by the{" "}
                            <strong>year. eg. 100000024</strong>{" "}
                          </li>
                        </ul>
                      </Alert>
                      {!isSubdomain && (
                        <InputSelect
                          mt={10}
                          size="lg"
                          label="Select School Placed"
                          name="schoolId"
                          placeholder="Select"
                          data={selectSchoolList}
                          defaultalue={`${retrieveVoucherVal.schoolId}`}
                        />
                      )}
                      <InputText
                        mt={10}
                        size="lg"
                        label="JHS Index Number"
                        name="jhsIndexNumber"
                        placeholder="10000000024"
                        maxLength={12}
                        defaultalue={retrieveVoucherVal.jhsIndexNumber}
                      />

                      {/* <InputText
                      size="lg"
                      label="Phone Number"
                      name="phoneNumber"
                      placeholder="eg. 0240000000"
                      maxLength={10}
                      defaultalue={retrieveVoucherVal.phoneNumber}
                    /> */}

                      <Button
                        size="lg"
                        mt={30}
                        type="button"
                        fullWidth
                        loading={loading}
                        variant="filled"
                        onClick={() =>
                          handleDataSubmit(handleSubmit, values, setValues)
                        }
                      >
                        Retrieve Voucher
                      </Button>
                      <Text align="center" mt={20}>
                        Do not have a voucher?{" "}
                        <strong>
                          <Link to={"/" + routes.ROUTE_BUY_VOUCHER}>
                            Buy Voucher
                          </Link>
                        </strong>
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Flex>
            </SimpleGrid>
            {/* </Flex> */}
          </>
        )}
      </Formik>
      {/* </Container> */}
    </div>
  );
}

export default RetrieveVoucher;
