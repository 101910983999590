import { Button, Center, Flex, Image, MantineProvider, Stack, Text } from '@mantine/core';
import React from 'react'


function UnpublishedSchoolNotice({school}) {
  return (
    <MantineProvider withGlobalClasses withCssVariables>
      <Flex
        size="lg"
        style={{
          minHeight: "95vh",
          backgroundColor: "#EEEEEE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Stack>
          <Center>
            <Image
              src={school?.logoUrl}
              maw={100}
              mah={100}
              alt="Failed route"
            />
          </Center>
          <Text size="25px" ta="center" mb={10} mt={10} fw="bold">{school?.name}</Text>
          <Text ta="center" c="red" size='35px'>
            Opening Soon. Please check back later!!
          </Text>
          <Center mt={10}>
            <Button
              variant="filled"
              onClick={() => {
               window.location.reload();

              }}
            >
              Try again
            </Button>
          </Center>
        </Stack>
      </Flex>
    </MantineProvider>
  )
}

export default UnpublishedSchoolNotice