import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AppMainShell from '../views/AppMainShell'
import WelcomeScreen from '../views/WelcomeScreen'
import {Routes as routes} from '../constants'
import NotFound from '../views/NotFound'
import BuyVoucher from '../views/buy-voucher/BuyVoucher'
import RetrieveVoucher from '../views/buy-voucher/RetrieveVoucher'
import ConfirmVoucherPurchase from '../views/buy-voucher/ConfirmVoucherPurchase'
import AdmissionFormLogin from '../views/admission/AdmissionFormLogin'
import AdmissionStatusLogin from '../views/admission-status/AdmissionStatusLogin'
import AdmissionStatusDashboard from '../views/admission-status/AdmissionStatusDashboard'
import AdmissionStudentData from '../views/admission-status/AdmissionStudentData'
import AdmissionFormDashboard from '../views/admission/AdmissionFormDashboard'
import SupportedSchools from '../views/navbar-menus/SupportedSchools'
import ContactUs from '../views/navbar-menus/ContactUs'
import AboutUs from '../views/navbar-menus/AboutUs'
import PrivacyPolicy from '../views/navbar-menus/PrivacyPolicy'

function HomePageRouter() {
  return (
    <Routes>
        <Route path="/" element={<AppMainShell />}>
        <Route index={true} element={<WelcomeScreen />} />
        <Route path={routes.ROUTE_BUY_VOUCHER} element={<BuyVoucher />} />
        <Route path={`${routes.ROUTE_CONFIRM_VOUCHER}`} element={<ConfirmVoucherPurchase />} />
        <Route path={routes.ROUTE_RETRIEVE_VOUCHER} element={<RetrieveVoucher />} />

        <Route path={routes.ROUTE_ADMISSION} element={<AdmissionFormLogin />} />
        <Route path={routes.ROUTE_ADMISSION_FORM_DASHBOARD} element={<AdmissionFormDashboard />} />
        {/* <Route path={routes.ROUTE_ADMISSION_FORM} element={<AdmissionFormMain />} /> */}
        <Route path={routes.ROUTE_ADMISSION_STATUS} element={<AdmissionStatusLogin />}></Route>
        {/* <Route path={routes.ROUTE_ADMISSION_PREVIEW} element={<AdmissionFormPreview />} /> */}
        <Route path={`${routes.ROUTE_ADMISSION_STATUS}/:indexnumber`} element={<AdmissionStatusDashboard />} />
        {/* <Route path={routes.ROUTE_ADMISSION_LETTER} element={<AdmissionLetter />} /> */}
        <Route path={routes.ROUTE_ADMISSION_BIODATA} element={<AdmissionStudentData />} />

        <Route path={routes.ROUTE_SUPPORTED_SCHOOL} element={<SupportedSchools />} />
        <Route path={routes.ROUTE_CONTACT_US} element={<ContactUs />} />
        <Route path={routes.ROUTE_ABOUT_US} element={<AboutUs />} />
        <Route path={routes.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default HomePageRouter