/**
 * The current environment the node server is running on
 */
const NODE_ENVIRONMENT = "staging" || process.env.NODE_ENV;

/**
 * This endpoint returns the base url of any environment.
 * This is in an object. `getBaseUrl(process.env.NODE_ENV).BASE_URL` to get the base url of any environment
 * @param {*} env the node environment. process.env.NODE_ENV
 * @returns
 */
const getBaseUrl = (env) => {
  const URL = {
    development: {
      // API_URL: 'http://ec2-3-142-93-225.us-east-2.compute.amazonaws.com'
      BASE_URL: "https://api.shsadmissions.com",
    },
    production: {
      BASE_URL: "https://api.shsadmissions.com",
    },
    staging: {
      BASE_URL: "https://api.shsadmissions.com",
    },
  };

  return URL[env] || URL["development"];
};

const QueryKeys = Object.freeze({
  USER: "user",
  SCHOOL: 'school',
  STUDENT_VOUCHER: 'studentVoucher',
  SUBMITTED_STUDENT:'submittedStudent',
  CONFIRM_VOUCHER_CALLBACK:"confirmVoucherCallback",
  ADMISSION_STATUS_STUDENT:"admissionStatusStudent"
});

const Routes = Object.freeze({
  ROUTE_BUY_VOUCHER: "code/buy",
  ROUTE_CONFIRM_VOUCHER: "code/verify",
  ROUTE_RETRIEVE_VOUCHER: "code/retrieve",
  ROUTE_ADMISSION_STATUS: "admission/status",
  ROUTE_ADMISSION: "admission",
  ROUTE_ADMISSION_FORM_DASHBOARD:'admission/dashboard',
  ROUTE_ADMISSION_FORM: "admission/form",
  ROUTE_ADMISSION_PREVIEW: "admission/confirm",
  ROUTE_ADMISSION_LETTER: "admission/letter",
  ROUTE_ADMISSION_BIODATA: "admission/biodata",
  ROUTE_CONTACT_US: "contact-us",
  ROUTE_ABOUT_US: "about-us",
  ROUTE_SUPPORTED_SCHOOL: "supported-schools",
  ROUTE_PRIVACY_POLICY: "privacy-policy",
});

export { getBaseUrl, Routes, QueryKeys, NODE_ENVIRONMENT };
