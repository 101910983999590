import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import correctIcon from "../../assets/correct.png";
import wrongIcon from "../../assets/wrong.png";
import loadingIcon from "../../assets/loading-state.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as localStore from "../../store/local/localStore";
import * as apiVoucher from "../../api/voucher";
import { modals } from "@mantine/modals";
import PageTitle from "../../components/header/PageTitle";
import { Routes as routes } from "../../constants";
import { notifications } from "@mantine/notifications";
import logger from "../../utils/logger";

function ConfirmVoucherPurchase() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [purchaseResponse, setPurchaseResponse] = useState({
    title: "Voucher Purchase Confirmation",
    message: "Please wait while we confirm your voucher purchase",
    icon: loadingIcon,
  });

  const showPurchasedVoucher = useCallback(
    (voucherNumbers) => {
      function navigateToAdmissionForm(modals) {
        modals.closeAll();
        navigate(`/${routes.ROUTE_ADMISSION_FORM_DASHBOARD}`, {
          replace: true,
        });
      }
      modals.open({
        title: "KEEP VOUCHER SAFE",
        closeOnClickOutside: false,
        onClose: () => {
          navigateToAdmissionForm(modals);
        },
        children: (
          <>
            {voucherNumbers}
            <Button
              mt={20}
              fullWidth
              onClick={() => {
                navigateToAdmissionForm(modals);
              }}
            >
              Continue
            </Button>
          </>
        ),
      });
    },
    [navigate]
  );

  const confirmVoucherPurchase = useCallback(async () => {
    const reference = searchParams?.get("reference");
    if (!reference) {
      notifications.show({
        title: "Error",
        message: "No reference found",
        color: "red",
      });
      navigate("/");
      return;
    }

    const jhsIndexNumber = localStore.getStudentVoucherDetails().jhsIndexNumber;

    const response = await apiVoucher.confirmVoucher({
      jhsIndexNumber: jhsIndexNumber,
      reference: reference,
    });

    if (!response.ok) {
      setPurchaseResponse({
        title: "Confirmation Failed",
        message: response.data.error,
        icon: wrongIcon,
      });
      return;
    }

    logger("response", response.data);

    //if verification is successful
    //save the user data and redirect to the admission form registration page
    localStore.storeStudentVoucherDetails(response.data.data);

    const verifyVoucherResponse = await apiVoucher.verifyVoucher({
      jhsIndexNumber: response.data.data.jhsIndexNumber,
      voucherNumber: response.data.data.voucherNumber,
    });
    if (!verifyVoucherResponse.ok) {
      notifications.show({
        title: "Error",
        message:
          verifyVoucherResponse?.data?.message || "Voucher verification failed",
        color: "red",
      });
    }

    localStore.storeStudentDetails(verifyVoucherResponse.data.student);
    localStore.storeSchoolDetails(verifyVoucherResponse.data);

    setPurchaseResponse({
      title: "Confirmation Success",
      message: "Payment Successful",
      icon: correctIcon,
    });

    setTimeout(() => {
      let voucherNumber = (
        <div>
          Your Voucher Number is:{" "}
          <strong>{response.data.data.voucherNumber}</strong>
        </div>
      );

      showPurchasedVoucher(voucherNumber);
    }, 1500);
  }, [navigate, searchParams, showPurchasedVoucher]);

  useEffect(() => {
    confirmVoucherPurchase();
  }, [confirmVoucherPurchase]);
  return (
    <>
      <PageTitle title="Voucher Confirmation" />
      <Flex
        style={{
          minHeight: "95vh",
          backgroundColor: "#EEEEEE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Container>
          <Image
            src={purchaseResponse.icon}
            height={60}
            width={60}
            fit="contain"
            alt="Verification State Icon"
            mt={20}
            mb={10}
          />
          <Title ta="center" order={4}>
            {purchaseResponse.title}
          </Title>
          <Text ta="center">{purchaseResponse.message}</Text>
          <Center>
            {purchaseResponse.icon === wrongIcon && (
              <Button
                size="lg"
                mt={10}
                variant="contained"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back
              </Button>
            )}
          </Center>
        </Container>
      </Flex>
    </>
  );
}

export default ConfirmVoucherPurchase;
