import { useEffect } from "react"
import { Group, Modal, Button, Text } from '@mantine/core';

const NoticeDialog = ({ opened, buttonText, handleOnClose, dialogMessage, size="lg"}) => {

    useEffect(() => {

    }, [])

    return <>

        <Modal
            title='Notice'
            opened={opened}
            withCloseButton
            onClose={handleOnClose}
            closeOnClickOutside={false}
            size={size}
            radius="md"
        >
            <Text size="md" style={{ marginBottom: 10 }} >
                {dialogMessage}
            </Text>

            <Group position="center">
                <Button fullWidth onClick={handleOnClose}>{buttonText}</Button>
            </Group>

        </Modal>
    </>

}

export default NoticeDialog