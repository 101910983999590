const isSubdmoan = "isSubdomain";

/**
 * This function stores if a user is on the subdomain or not
 * @param {*} value 
 */
const sessionSetIsSubdomain = (value) => {
    sessionStorage.setItem(isSubdmoan, value);
}

/**
 * This function returns if a user is on a subdomain
 * @returns auth token
 */
const sessionGetIsSubdomain = ()=>{
    return sessionStorage.getItem(isSubdmoan);
}


export {
    sessionSetIsSubdomain, sessionGetIsSubdomain
}