import apiClient from "./client";
import { buyVoucher } from "@Crafterhive/cssps";

/**
 * This endpoint sends request for user to buy voucher
 * @param {*} values - JSON object of {jhsIndexNumber, phoneNumber, schoolId, callbackUrl}
 * @returns Promise
 */
// const buyVoucher = (values) => {
//     return apiClient.post('/voucher/buy', values)
// }

/**
 * This endpoint sends request to confirm a bought voucher
 * @param {*} values - JSON object of {jhsIndexNumber, reference}
 * @returns Promise
 */
const confirmVoucher = (values) => {
    return apiClient.post('/voucher/buy/confirm', values)
}

/**
 * This endpoint sends request to verify a voucher code
 * @param {*} values - JSON object of {jhsIndexNumber, voucherNumber}
 * @returns Promise
 */
const verifyVoucher = (values) => {
    return apiClient.post('/voucher/verify', values)
}

/**
 * This endpoint sends request for user to retrieve
 * @param {*} values - JSON object of {jhsIndexNumber, phoneNumber, schoolId, callbackUrl}
 * @returns Promise
 */
const retrieveVoucher = (values) => {
    return apiClient.post('/voucher/retrieve', values)
}

export { buyVoucher, confirmVoucher, verifyVoucher, retrieveVoucher }