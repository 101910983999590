import React, { useEffect, useState } from "react";
import * as apiSchool from "../../api/school";
import PageTitle from "../../components/header/PageTitle";
import {
  Card,
  Container,
  Flex,
  Grid,
  
  Image,
  Text,
  
} from "@mantine/core";
import { NavLink } from "react-router-dom";
import logger from "../../utils/logger";
import { useFetchPublishedSchoolsQuery } from "../../api/rtk-query-apis";

function SupportedSchools() {
  const {data=[], isFetching, } = useFetchPublishedSchoolsQuery();
  const [publishedSchools, setPublishedSchools] = useState([]);

  const getSchools = async () => {
    const response = await apiSchool.fetchPublishedSchools();
    logger("supported", response.data);
    if (response.ok) {
      setPublishedSchools(response.data);
    }
  };

  useEffect(() => {
    // getSchools();
    if(!isFetching && data){
      setPublishedSchools(data)
    }
  }, [data, isFetching]);

  return (
    <>
      <PageTitle title="Supported School" />
      <Container size="lg" mt={50}>
        <Text ta="center" size="25px" fw="bold" mb={10}>
          Supported Schools
        </Text>
        <Text ta="center" size="lg" mb={20}>
          Take a look at our supported schools
        </Text>
        <Grid>
          {publishedSchools.map((value, key) => {
            return (
              <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, xs: 12 }} key={key}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={`https://${value?.nickName}.shsadmissions.com`}
                >
                  <Card h={100} shadow="sm" withBorder>
                    <Flex align="center">
                      {value?.logoUrl && (
                        <Image
                          src={value?.logoUrl}
                          height={70}
                          width={70}
                          alt="School Crest"
                          fit="contain"
                        />
                      )}
                      <Text size="lg" ml={10} fw="normal" textWrap="wrap">
                        {value?.name}
                      </Text>
                    </Flex>
                  </Card>
                </NavLink>
              </Grid.Col>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}

export default SupportedSchools;
