import {
  BackgroundImage,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Image,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import * as localStore from "../../store/local/localStore";
import { Routes as routes } from "../../constants";
import { useNavigate } from "react-router-dom";
import iconSchool from "../../assets/school.png";

function SchoolPage({ handleGetStartedClick }) {
  const [school, setSchool] = useState(localStore.getSchoolDetails() || {});
  const navigate = useNavigate();

  useEffect(() => {});

  const handleButtonClick = (route) => {
    navigate(route);
  };

  return (
    <BackgroundImage
      style={{
        minHeight: "95vh",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      src={school?.images?.length > 0 ? school?.images[0] : null}
    >
      <Container size="lg" miw="70%" >
        <Card padding="md" bg={"rgba(255,255,255,0.95)"}>
          <Center m={20}>
            <Image
              w={100}
              h={100}
              fit="contain"
              src={
                school?.school?.logoUrl ? school?.school?.logoUrl : iconSchool
              }
            />
          </Center>
          <Center>
            <Text ta="center"
              style={{ fontSize: "30px" }}
              autoCapitalize="characters"
              fw="bolder"
            >
              {school?.school?.name}
            </Text>
          </Center>
          <Center mb={20}>
            <Text ta="center">
              Get Admission in the comfort of your home. <br />
              Just report to school. <br />
              No long queue!!!
            </Text>
          </Center>
          {/* <Center hidden>
              <Text c="red" size="40px">Opening Soon. Please check back later!</Text>
          </Center> */}
          <Grid align="center">
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
              fullWidth
                variant="filled"
                style={{ height: 80}}
                onClick={() => handleButtonClick(routes.ROUTE_BUY_VOUCHER)}
              >
                Buy Voucher
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
                variant="filled"
                fullWidth
                style={{ height: 80}}
                onClick={() => handleButtonClick(routes.ROUTE_RETRIEVE_VOUCHER)}
              >
                Retrieve Voucher Code
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
              fullWidth
                variant="filled"
                style={{ height: 80 }}
                onClick={() => handleButtonClick(routes.ROUTE_ADMISSION)}
              >
                Fill Admission Form
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
              fullWidth
                variant="filled"
                style={{ height: 80}}
                onClick={() => handleButtonClick(routes.ROUTE_ADMISSION_STATUS)}
              >
                Download Prospectus
              </Button>
            </Grid.Col>
          </Grid>
        </Card>
      </Container>
    </BackgroundImage>
  );
}

export default SchoolPage;
