import {
  Alert,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import SchoolPicture from "../../components/school/SchoolPicture";
import { useMediaQuery } from "@mantine/hooks";
import { sessionGetIsSubdomain } from "../../store/local/sessionStore";
import { InputText } from "../../components/input";
import { modals } from "@mantine/modals";
import * as Yup from "yup";
import * as localStore from "../../store/local/localStore";
import * as apiAdmission from "../../api/admission";
import iconVoucher from "../../assets/voucher.png";
import { Link, useNavigate } from "react-router-dom";
import { Routes as routes } from "../../constants";
import { notifications } from "@mantine/notifications";
import * as apiVoucher from "../../api/voucher";
import SchoolPictureCarousel from "../../components/school/SchoolPictureCarousel";
import PageTitle from "../../components/header/PageTitle";
import logger from "../../utils/logger";

const validationSchema = Yup.object().shape({
  // schoolId: Yup.string().required().label("School Placed"),
  jhsIndexNumber: Yup.string()
    .required()
    .min(12)
    .max(12)
    .label("JHS Index Number"),
  voucherNumber: Yup.string()
    .required()
    .min(10)
    .max(10)
    .label("Voucher Number"),
});
function AdmissionFormLogin() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState(localStore.getSchoolDetails());
  const isSubdomain = JSON.parse(sessionGetIsSubdomain());
  const [admission, setAdmission] = useState({
    jhsIndexNumber: "",
    voucherNumber: "",
  });

  //if a user has already submitted their admission form, show this dialog and when its closed, go to home
  const showAlreadySubmittedFormModal = useCallback(() => {
    function navigateBack(modals) {
      modals.closeAll();
    }
    modals.open({
      title: "Information",
      closeOnClickOutside: false,
      onClose: () => {
        navigateBack(modals);
      },
      children: (
        <>
          <Text mb={20}>You have already submitted your admission details</Text>
          <Button
            fullWidth
            onClick={() => {
              navigateBack(modals);
            }}
          >
            Close
          </Button>
        </>
      ),
    });
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);

    const verifyVoucherResponse = await apiVoucher.verifyVoucher(values);
    logger("verify voucher", verifyVoucherResponse.data);
    if (!verifyVoucherResponse.ok) {
      setLoading(false);
      notifications.show({
        title: "Error",
        message: verifyVoucherResponse.data.message,
        color: "red",
      });
      return;
    }

    //if verification success,

    //check if user has already filled the form
    const sch = verifyVoucherResponse.data;
    const checkForm = await apiAdmission.getProspectus({
      ...values,
      schoolId: sch.school.id,
    });
    setLoading(false);
    if (checkForm.ok) {
      //user has already filled form
      showAlreadySubmittedFormModal();
      return;
    }

    //save data to session storage
    localStore.storeStudentVoucherDetails({
      jhsIndexNumber: values.jhsIndexNumber,
      voucherNumber: values.voucherNumber,
    });

    localStore.storeStudentDetails(verifyVoucherResponse.data.student);
    if (localStore.getSchoolDetails()) {
      localStore.storeSchoolDetails(sch);
    }
    //navigate to fill admission form
    navigate("/" + routes.ROUTE_ADMISSION_FORM_DASHBOARD);
  };

  return (
    <div>
      <PageTitle title="Fill Form"/>
      <Formik
        initialValues={admission}
        //   validate={validate}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, setValues, values }) => (
          <>
            <SimpleGrid
              cols={{ sm: 2, xs: 1, md: 2, lg: 2, xl: 2 }}
              spacing="lg"
            >
              {!isSmallScreen && (
                <Flex
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  bg="#EEEEEE"
                  h="95vh"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <Grid justify="center" mt="10%">
                    <Grid.Col>
                      <SchoolPictureCarousel />
                    </Grid.Col>
                  </Grid>
                </Flex>
              )}
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Card>
                  <Center>
                    <Image
                      width={100}
                      height={100}
                      fit="contain"
                      src={
                        school && isSubdomain === true
                          ? school?.school?.logoUrl
                          : iconVoucher
                      }
                      alt="School logo"
                    />
                  </Center>
                  <Grid justify="center" mt={5}>
                    <Grid.Col
                      span={{ lg: 8, md: 8, sm: 12, xs: 12 }}
                      className="flex-center"
                    >
                      <Alert>
                        <ul>
                          {!school && (
                            <li>
                              Select the exact school you're placed from the
                              list below.
                            </li>
                          )}
                          <li>
                            Enter your 10 digits Index Number followed by the{" "}
                            <strong>year. eg. 100000024</strong>{" "}
                          </li>
                          <li>Enter your voucher number to fill admission form</li>
                        </ul>
                      </Alert>

                      <InputText
                        mt={10}
                        size="lg"
                        label="JHS Index Number"
                        name="jhsIndexNumber"
                        placeholder="10000000024"
                        maxLength={12}
                        defaultalue={admission.jhsIndexNumber}
                      />

                      <InputText
                        size="lg"
                        label="Voucher Number"
                        name="voucherNumber"
                        placeholder="Enter Voucher Number Received via SMS"
                        defaultalue={admission.voucherNumber}
                      />

                      <Button
                        size="lg"
                        mt={30}
                        type="button"
                        fullWidth
                        loading={loading}
                        variant="filled"
                        onClick={handleSubmit}
                      >
                        Fill Form
                      </Button>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Flex>
            </SimpleGrid>
          </>
        )}
      </Formik>
    </div>
  );
}

export default AdmissionFormLogin;
