import React, { useRef, useState } from "react";
import * as localStore from "../../store/local/localStore";
import { sessionGetIsSubdomain } from "../../store/local/sessionStore";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import girlHomeImage from "../../assets/girl-home.png";
import { Image } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";

function SchoolPictureCarousel() {
  const [school, setSchool] = useState(localStore.getSchoolDetails() || null);
  const isSubdomain = JSON.parse(sessionGetIsSubdomain());
  const [images, setImages] = useState(
    school?.images?.length > 0 ? school?.images : []
  );
  const [embla, setEmbla] = useState(null);
  //used for autoplay duration
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  //used to fix carousel animation offset
  useAnimationOffsetEffect(embla, 200);

  return (
    <div>
      {images?.length > 0 && isSubdomain ? (
        <Carousel loop getEmblaApi={setEmbla} plugins={[autoplay.current]}>
          {images.map((image, key) => (
            <Carousel.Slide key={key}>
              <Image src={image} fit="fill" w="100%" mah={450} radius={10} />
            </Carousel.Slide>
          ))}
        </Carousel>
      ) : (
        <img
          src={girlHomeImage}
          alt="Girl sitting on couch with PC on lap"
          style={{ width: "100%", height: "300px", borderRadius: 5 }}
          className="img-responsive"
        />
      )}
    </div>
  );
}

export default SchoolPictureCarousel;
