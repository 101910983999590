import { useFormikContext } from 'formik'
import React from 'react'
import { TextInput, Tooltip, useMantineTheme } from '@mantine/core'

function InputText({ label, name, placeholder = "", size = "sm", tooltip, ...props }) {
    const { errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext()
    const {fontSizes} = useMantineTheme();

    const rightSection = (
        <Tooltip label={tooltip} position="top" placement="end">
            <i className='bi-patch-question-fill' style={{ display: 'block', fontSize: 20, opacity: 0.8 }} />
        </Tooltip>
    );

    return (
        <TextInput
            label={label}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            size={size}
            error={(touched[name] && errors[name]) ? errors[name] : false}
            errorProps={{ style: { fontSize: fontSizes.xs} }}
            rightSection={tooltip ? rightSection : null}
            mb={10}
            {...props}
        />
    )
}

export default InputText