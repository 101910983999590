import {
    BackgroundImage,
    Button,
    Card,
    Center,
    Container,
    Grid,
    Image,
    Text,
  } from "@mantine/core";
  import React, { useEffect } from "react";
  import { Routes as routes } from "../../constants";
  import { useNavigate } from "react-router-dom";
  import imageITGirl from '../../assets/girl-home.png'
  function GenericPage() {
    const navigate = useNavigate();
  
    useEffect(() => {});
  
    const handleButtonClick = (route) => {
      navigate(route);
    };
  
  return (
    <BackgroundImage
      style={{
        minHeight: "95vh",
        backgroundColor: "#EEEEEE",
        paddingTop:50,
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        height: "100vh",
      }}
    >
      <Container size="lg" miw="70%">
       
        <Card padding="md" >
          <Center m={20}>
            <Image
              w={500}
              h={300}
              fit="contain"
              src={
                imageITGirl
              }
            />
          </Center>
         
          <Center mb={20}>
            <Text ta="center" size="lg">
              Get Admission in the comfort of your home. <br />
              Just report to school. <br />
              No long queue!!!
            </Text>
          </Center>
          {/* <Center hidden>
              <Text c="red" size="40px">Opening Soon. Please check back later!</Text>
          </Center> */}
          <Grid align="center" >
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
                variant="filled"
                style={{ height: 80, width: "100%" }}
                onClick={() => handleButtonClick(routes.ROUTE_BUY_VOUCHER)}
              >
                Buy Voucher
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
                variant="filled"
                style={{ height: 80, width: "100%" }}
                onClick={() => handleButtonClick(routes.ROUTE_RETRIEVE_VOUCHER)}
              >
                Retrieve Voucher Code
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
                variant="filled"
                style={{ height: 80, width: "100%" }}
                onClick={() => handleButtonClick(routes.ROUTE_ADMISSION)}
              >
                Fill Admission Form
              </Button>
            </Grid.Col>
            <Grid.Col span={{ xl: 3, lg: 3, md: 3, sm: 12, xs: 12 }}>
              <Button
                variant="filled"
                style={{ height: 80, width: "100%" }}
                onClick={() => handleButtonClick(routes.ROUTE_ADMISSION_STATUS)}
              >
                Download Prospectus
              </Button>
            </Grid.Col>
          </Grid>
        </Card>
      </Container>
    </BackgroundImage>
  )
}

export default GenericPage
