import { AppShell } from "@mantine/core";
import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderMenu } from "../components/menu/HeaderMenu";
import { Routes as AppRoutes } from "../constants";

const links = [
  {
    link: ``,
    label: "Home",
  },
  {
    link: `${AppRoutes.ROUTE_SUPPORTED_SCHOOL}`,
    label: "Supported Schools",
  },
  { link: `${AppRoutes.ROUTE_CONTACT_US}`, label: "Contact Us" },
  {
    link: `${AppRoutes.ROUTE_ABOUT_US}`,
    label: "About Us",
  },
  {
    link: `${AppRoutes.ROUTE_PRIVACY_POLICY}`,
    label: "Privacy Policy",
  },
  //this should be the last route
  // {
  //   link: AppRoutes.LOGOUT,
  //   label: 'Logout'
  // }
];

function AppMainShell() {
  return (
    <AppShell
      header={{ height: 60 }}
      // navbar={{ width: 300, breakpoint: "sm", collapsed: { mobile: !opened } }}
      top="md"
    >
      <AppShell.Header>
        {/* <Group h="100%" px="md"> */}
        {/* <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" /> */}
        {/* <Dashboard size={30} />      */}
        <HeaderMenu links={links} />
        {/* </Group> */}
      </AppShell.Header>
      {/* <AppShell.Navbar p="md">Navbar</AppShell.Navbar> */}

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

export default AppMainShell;
