import {
  Button,
  Center,
  Flex,
  Image,
  MantineProvider,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";
import iconBrokenLink from "../assets/broken-link.png";

function NotFound() {
  return (
    <MantineProvider withGlobalClasses withCssVariables>
      <Flex
        size="lg"
        style={{
          minHeight: "95vh",
          backgroundColor: "#EEEEEE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Stack>
          <Center>
            <Image
              src={iconBrokenLink}
              maw={100}
              mah={100}
              alt="Failed route"
            />
          </Center>
          <Text ta="center">
            Oops! The page you requested for is not found on our server <br />
            Check your url or click <strong>Home</strong>
          </Text>
          <Center>
            <Button
             size="lg"
              variant="filled"
              onClick={() => {
                window.location = "https://shsadmissions.com";
              }}
            >
              Home
            </Button>
          </Center>
        </Stack>
      </Flex>
    </MantineProvider>
  );
}

export default NotFound;
