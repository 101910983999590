import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NODE_ENVIRONMENT, getBaseUrl } from "../constants";
// import { getAuthToken } from "../store/local/localStore";

const url = getBaseUrl(NODE_ENVIRONMENT);

//define a service using a base url and expected endpoints
export const shsAdmissionsApi = createApi({
  reducerPath: "shsAdmissionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: url.BASE_URL,
    prepareHeaders(headers) {
    //   headers.set("authorization", `Bearer ${getAuthToken()}`);
      // headers.set() --set multiple headers
      return headers;
    },
  }),
  endpoints: (builder) => ({
    /**Query endpoints relating to schools */
    fetchSchoolByNickname: builder.query({
      query: (nickname) => `/school/nickname/${nickname}`,
    }),
    
    //fetch all published schools
    fetchPublishedSchools: builder.query({
      query: ()=> `/schools/published`
    }),
  }),
});

//export autogenerated hooks
export const {
    useFetchSchoolByNicknameQuery,
    useFetchPublishedSchoolsQuery,
    
    useLazyFetchPublishedSchoolsQuery
} = shsAdmissionsApi;
