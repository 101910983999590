import { Alert, Button, Container, Group, LoadingOverlay, Stack, Table } from "@mantine/core";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as localStore from "../../store/local/localStore";
import { modals } from "@mantine/modals";
import * as apiAdmission from "../../api/admission";
import { notifications } from "@mantine/notifications";
import { Routes as routes } from "../../constants";
import logger from "../../utils/logger";
function StepTwoAdmissionFormPreview({
  admiData,
  student,
  onSubmit,
  onNextClick,
  onPreviousClick,
}) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [studentVoucher, setStudentVoucher] = useState(
    localStore.getStudentVoucherDetails()
  );

  const colors = {
    lightgray: "#eeeeee",
  };

  const showComfirmModal = () => {
    modals.openConfirmModal({
      title: "Submit",
      children: (
        <>
          You will <strong>NOT</strong> be able to change your information after
          submitting
        </>
      ),
      labels: { confirm: "Submit", cancel: "Cancel" },
      onConfirm: () => {
        //confirm
        submitData();
      },
      onCancel: () => {},
    });
  };

  const submitData = async () => {
    // setDialogMessage(<div>Submiting data... please wait</div>);
    setLoading(true);
    const response = await apiAdmission.submitStudentForm(admiData);
    setLoading(false);

    logger("submitted response", response.data);
    if (!response.ok) {
      notifications.show({ message: response?.data?.message || "Unexpected Error. Try again", color: "red" });
      return;
    }

    localStore.storeSubmittedStudentDetails(response.data);

    //move to the next step
    onNextClick();

    setTimeout(() => {
      navigate(
        `/${routes.ROUTE_ADMISSION_STATUS}/${studentVoucher.jhsIndexNumber}`
      );
    }, 2000);
  };

  return (
    <div>
      <Container size="lg">
        <LoadingOverlay visible={loading}/>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Stack>
            <div id="printToPDF">
              <Table verticalSpacing="sm" horizontalSpacing="xl" striped withTableBorder highlightOnHover>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td
                      colSpan={2}
                      style={{
                        backgroundColor: colors.lightgray,
                        borderRadius: 5,
                      }}
                    >
                      <h3>Student Biodata</h3>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Name</div>
                      <strong>{student.name}</strong>
                    </Table.Td>
                    <Table.Td>
                      <div>Program Offered</div>
                      <strong>{student.programOffered}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>JHS Index Number</div>
                      <strong>{admiData.jhsIndexNumber}</strong>
                    </Table.Td>
                    <Table.Td>
                      <div>Track</div>
                      <strong>{student.track}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Enrolment Code</div>
                      <strong>{admiData.enrolmentCode}</strong>
                    </Table.Td>
                    <Table.Td>
                      <div>Date of Birth</div>
                      <strong>
                        {dayjs(admiData.dateOfBirth).format("DD MMM, YYYY")}
                      </strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Place of Birth</div>
                      <strong>{admiData.placeOfBirth}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Address</div>
                      <strong>{admiData.address}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Hometown</div>
                      <strong>{admiData.hometown}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Phone Number</div>
                      <strong>{admiData.phoneNumber}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Nationality</div>
                      <strong>{admiData.nationality}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Physical Challenge</div>
                      <strong>{admiData.physicalChallenge}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Region</div>
                      <strong>{admiData.region}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Religious Denomination</div>
                      <strong>{admiData.religiousDenomination}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Name of Last School Attended</div>
                      <strong>{admiData.lastSchoolAttended}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Address of Last School</div>
                      <strong>
                        {admiData.addressOfLastSchoolAttended}
                      </strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td
                      colSpan={2}
                      style={{
                        backgroundColor: colors.lightgray,
                        borderRadius: 5,
                      }}
                    >
                      <h3>Parent Information</h3>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Name of Father</div>
                      <strong>{admiData.nameOfFather}</strong>{" "}
                      {/* <TitleValueText title="Name of Father" value={admiData.nameOfFather} /> */}
                    </Table.Td>
                    <Table.Td>
                      <div>Name of Mother</div>
                      <strong>{admiData.nameOfMother}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Occupation of Father</div>
                      <strong>{admiData.occupationOfFather}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Occupation of Mother</div>
                      <strong>{admiData.occupationOfMother}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Address of Father</div>
                      <strong>{admiData.addressOfFather}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Address of Mother</div>
                      <strong>{admiData.addressOfMother}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Phone Number of Father</div>
                      <strong>{admiData.phoneNumberOfFather}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Phone Number of Mother</div>
                      <strong>{admiData.phoneNumberOfMother}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td
                      colSpan={2}
                      style={{
                        backgroundColor: colors.lightgray,
                        borderRadius: 5,
                      }}
                    >
                      <h3>Guardian Information</h3>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Name of Guardian</div>
                      <strong>{admiData.nameOfGuardian}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Occupation of Guardian</div>
                      <strong>{admiData.occupationOfGuardian}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Phone Number of Guardian</div>
                      <strong>{admiData.phoneNumberOfGuardian}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Residential Address of Guardian</div>
                      <strong>
                        {admiData.residentialAddressOfGuardian}
                      </strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>
                      <div>Postal Address Of Guardian</div>
                      <strong>{admiData.postalAddressOfGuardian}</strong>{" "}
                    </Table.Td>
                    <Table.Td>
                      <div>Guardian</div>
                      <strong>{admiData?.guardian}</strong>{" "}
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </div>
            <div>
              <Alert variant="light" color="orange" className="mb-4">
                By clicking on <strong>Submit Details</strong>, you have accepted that
                the data provided is correct. You will <strong>NOT</strong> be
                able to update the details later after confirmation
              </Alert>
            </div>
            <Group justify="space-between" align="center" mt={20} mb={40}>
              <Button
                size="lg"
                variant="outline"
                style={{ marginRight: 20 }}
                onClick={onPreviousClick}
              >
                Back
              </Button>
              <Button
                variant="filled"
                size="lg"
                onClick={showComfirmModal}
                loading={loading}
              >
                Submit Details
              </Button>
            </Group>
          </Stack>
        </div>
      </Container>
    </div>
  );
}

export default StepTwoAdmissionFormPreview;
