import React, { useEffect, useRef, useState } from "react";
import * as localStore from "../../store/local/localStore";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Routes as routes } from "../../constants";
import { Container, Table } from "@mantine/core";

function AdmissionStudentData() {
  const colors = {
    lightgray: "#eeeeee",
  };
  const [admissionData, setAdmissionData] = useState(
    localStore.getStudentAdmissionData()
  );
  const [school, setSchool] = useState(localStore.getSchoolDetails());
  const navigate = useNavigate();
  const printCompRef = useRef();

  const handlePrint = useReactToPrint({
    // 230mm 380mm
    contentRef: printCompRef,
    pageStyle: `@page {
            size: 280mm 440mm;
            margin: 5mm;
            
                }`,
  });

  useEffect(() => {
    handlePrint();
    // printPDF()
    navigate("/" + routes.ROUTE_ADMISSION_STATUS + "/" + admissionData?.jhsIndexNumber, { replace: true })
  }, [admissionData?.jhsIndexNumber, handlePrint, navigate]);

  return (
    <div>
      <Container size="lg">
        <div id="printToPDF" ref={printCompRef} style={{ width: "100%" }}>
          <div>
            <h3 style={{ display: "flex", justifyContent: "center" }}>
              {school?.school?.name}
            </h3>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Table  striped className="styled-table">
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td
                    colSpan={2}
                    style={{
                      backgroundColor: colors.lightgray,
                      borderRadius: 5,
                      paddingLeft:10
                    }}
                  >
                    <h3>Student Biodata</h3>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Full Name</div>
                    <strong>{admissionData?.name}</strong>
                  </Table.Td>
                  <Table.Td>
                    <div>Sex</div>
                    <strong>{admissionData?.gender}</strong>
                  </Table.Td>
                </Table.Tr>

                <Table.Tr>
                  <Table.Td>
                    <div>Enrolment Code</div>
                    <strong>{admissionData?.enrolmentCode}</strong>
                  </Table.Td>
                  <Table.Td>
                    <div>Date of Birth</div>
                    <strong>{admissionData?.dateOfBirth}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Place of Birth</div>
                    <strong>{admissionData?.placeOfBirth}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Address</div>
                    <strong>{admissionData?.address}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Hometown</div>
                    <strong>{admissionData?.hometown}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Phone Number</div>
                    <strong>{admissionData?.phoneNumber}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Nationality</div>
                    <strong>{admissionData?.nationality}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Physical Challenge</div>
                    <strong>{admissionData?.physicalChallenge}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Region</div>
                    <strong>{admissionData?.region}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Religious Denomination</div>
                    <strong>{admissionData?.religiousDenomination}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Name of Last School Attended</div>
                    <strong>
                      {admissionData?.lastSchoolAttended}
                    </strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Address of Last School</div>
                    <strong>
                      {admissionData?.addressOfLastSchoolAttended}
                    </strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td
                    colSpan={2}
                    style={{
                      backgroundColor: colors.lightgray,
                      borderRadius: 5,
                      paddingLeft:10
                    }}
                  >
                    <h3>Parent Information</h3>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Name of Father</div>
                    <strong>{admissionData?.nameOfFather}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Name of Mother</div>
                    <strong>{admissionData?.nameOfMother}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Occupation of Father</div>
                    <strong>{admissionData?.occupationOfFather}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Occupation of Mother</div>
                    <strong>{admissionData?.occupationOfMother}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Address of Father</div>
                    <strong>{admissionData?.addressOfFather}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Address of Mother</div>
                    <strong>{admissionData?.addressOfMother}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Phone Number of Father</div>
                    <strong>{admissionData?.phoneNumberOfFather}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Phone Number of Mother</div>
                    <strong>{admissionData?.phoneNumberOfMother}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td
                    colSpan={2}
                    style={{
                      backgroundColor: colors.lightgray,
                      borderRadius: 5,
                      paddingLeft:10
                    }}
                  >
                    <h3>Guardian Information</h3>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Name of Guardian</div>
                    <strong>{admissionData?.nameOfGuardian}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Occupation of Guardian</div>
                    <strong>{admissionData?.occupationOfGuardian}</strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Phone Number of Guardian</div>
                    <strong>{admissionData?.phoneNumberOfGuardian}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Residential Address of Guardian</div>
                    <strong>
                      {admissionData?.residentialAddressOfGuardian}
                    </strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Postal Address Of Guardian</div>
                    <strong>
                      {admissionData?.postalAddressOfGuardian}
                    </strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Guardian</div>
                    <strong>{admissionData?.guardian}</strong>{" "}
                  </Table.Td>
                </Table.Tr>

                <Table.Tr>
                  <Table.Td
                    colSpan={2}
                    style={{
                      backgroundColor: colors.lightgray,
                      borderRadius: 5,
                      paddingLeft:10
                    }}
                  >
                    <h3>Official Use Only</h3>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Admission Number</div>
                    <strong>{admissionData?.admissionNumber}</strong>
                  </Table.Td>
                  <Table.Td>
                    <div>BECE Index Number</div>
                    <strong>{admissionData?.jhsIndexNumber}</strong>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Track</div>
                    <strong>{admissionData?.track}</strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>Program Offered</div>
                    <strong>{admissionData?.programOffered}</strong>
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>Residential Status</div>
                    <strong>{admissionData?.residentialStatus}</strong>
                  </Table.Td>
                  <Table.Td>
                    <div>Class</div>
                    <strong></strong>{" "}
                  </Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>
                    <div>House Allocated</div>
                    <strong>
                      {admissionData?.houseAllocated?.houseName}
                    </strong>{" "}
                  </Table.Td>
                  <Table.Td>
                    <div>House Parent</div>
                    <strong>
                      {admissionData?.gender?.toLowerCase() === "male"
                        ? admissionData?.houseAllocated?.houseMaster
                        : admissionData?.houseAllocated?.houseMistress || admissionData?.houseAllocated?.houseMaster}
                    </strong>{" "}
                    {/* <strong>{(admissionData?.gender === "Female" && admissionData?.houseAllocated?.houseMistress !== null) ? admissionData?.houseAllocated?.houseMistress : admissionData?.houseAllocated?.houseMaster}</strong>{" "} */}
                  </Table.Td>
                </Table.Tr>

                <Table.Tr style={{ paddingBottom: 10 }}>
                  <Table.Td>
                    <div style={{ marginTop: 20 }}>
                      Student's
                      Signature:..................................................
                    </div>
                  </Table.Td>
                  <Table.Td>
                    <div style={{ marginTop: 20 }}>
                      Date(DD/MM/YYYY):.....................................................
                    </div>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AdmissionStudentData;
