import {
  Alert,
  Container,
  Table,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import React, { useCallback, useMemo, useState } from "react";
import * as localStore from "../../store/local/localStore";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { create } from "apisauce";
import * as apiVoucher from "../../api/voucher";
import * as apiAdmission from "../../api/admission";
import { getBaseUrl, Routes as routes } from "../../constants";

import NoticeDialog from "../../components/dialogs/NoticeDialog";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/header/PageTitle";
import {
  CloudDownload,
  Printer,
} from "tabler-icons-react";
import logger from "../../utils/logger";
function AdmissionStatusDashboard() {
  const [school, setSchool] = useState(localStore.getSchoolDetails());
  const [userVoucherDetails, setVoucherDetails] = useState(
    localStore.getStudentVoucherDetails()
  );

  const loadFile = async (url, callback) => {
    PizZipUtils.getBinaryContent(url, await callback);
  };

  const navigate = useNavigate();
  const [dialogOpen, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [downloadData, setDownloadData] = useState([]);
  const handleDialogClose = () => {
    setDialogOpened(false);
    navigate("/");
  };

  //this method verifies user's voucher code and indexNumber when this page is opened
  const verifyUser = useCallback(async () => {
    logger(userVoucherDetails);
    if (userVoucherDetails === null) {
      navigate("/");
      return;
    }

    const response = await apiVoucher.verifyVoucher({
      jhsIndexNumber: userVoucherDetails.jhsIndexNumber,
      voucherNumber: userVoucherDetails.voucherNumber,
    });
    if (!response.ok) {
      navigate("/");
      return;
    }
  }, [navigate, userVoucherDetails]);

  //get data
  const getAdmissionData = useCallback(async () => {
    const values = {
      schoolId: school?.school.id,
      jhsIndexNumber: userVoucherDetails.jhsIndexNumber,
      voucherNumber: userVoucherDetails.voucherNumber,
    };
    const response = await apiAdmission.getProspectus(values);
    logger("admission data", response);
    if (!response.ok) {
      //if response failed
      if (response.status !== 400) {
        // setOpenDialog({ open: true, title: 'Notice', message: <div> {response.data?.message }</div>})
        setDialogMessage(
          <div className="text-center" style={{ fontSize: 18 }}>
            {" "}
            {response.data?.message}
          </div>
        );
        setDialogOpened(true);
      }
      return;
    }

    //response is ok
    //set download data
    setDownloadData(response.data.documents);
    // storeUser('ddata', JSON.stringify(response.data));
    localStore.storeStudentAdmissionData(response.data);
  }, [
    school?.school.id,
    userVoucherDetails.jhsIndexNumber,
    userVoucherDetails.voucherNumber,
  ]);

  //when the print button is clicked
  const handlePrintClick = (route, data = {}) => {
    if (route === "biodata") {
      // setOpenPrintDialog({open:true, title:'Bio Data', children:StudentData })
      navigate("/" + routes.ROUTE_ADMISSION_BIODATA);
    } else if (route === "admission_letter") {
      downloadAdmissionLetter();
    } else if (route === "files") {
      window.open(data.url);
    }
  };


  const generateDocument = async (url) => {
    // const data = JSON.parse(retrieveUser('ddata'))
    const data = localStore.getStudentAdmissionData();
    const ap = create({});

    const da = await ap.get(url, "", {
      responseType: "",
      // headers:{
      //   'Content-Type': 'application/gzip'
      // }
      headers: {
        "Content-Type": "application/json",
      },
    });

    logger("da", da);

    await loadFile(url, function (error, content) {
      if (error) {
        throw error;
      }
      logger("responsetype", url);
      logger("document", content);
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      //render the document (replace all occurances)
      doc.render({
        name: data.name,
        programOffered: data?.programOffered,
        residentialStatus: data?.residentialStatus,
        houseAllocated: data?.houseAllocated?.houseName,
      });

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //output using data uri

      saveAs(out, "Admission_Letter.docx");
    });
  };

  const downloadAdmissionLetter = async () => {
    const url = getBaseUrl(process.env.NODE_ENV).BASE_URL;
    const values = {
      jhsIndexNumber: userVoucherDetails.jhsIndexNumber,
      voucherNumber: userVoucherDetails.voucherNumber,
      schoolId: school?.school.id,
    };
    const ff = `${url}/student/admission-letter/${values.schoolId}/${values.jhsIndexNumber}/${values.voucherNumber}`;
    window.open(ff);
  };

  useMemo(() => {
    verifyUser();
    getAdmissionData();
  }, [getAdmissionData, verifyUser]);

  return (
    <div>
      <PageTitle title="Admission Status" />
      <Container size="lg" mt={50}>
        <Alert mb={20} ta="center">
          Download, Print and complete the following documents. Carefully follow
          the instructions on each document and present them on arrival to
          campus <strong>including</strong> your ADMISSION LETTER.
        </Alert>
        <Table verticalSpacing="md" striped withColumnBorders highlightOnHover withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>No.</Table.Th>
              <Table.Th>Description</Table.Th>
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>1</Table.Td>
              <Table.Td>Biodata</Table.Td>
              <Table.Td align="center">
                <Tooltip label="Print">
                  <UnstyledButton onClick={() => handlePrintClick("biodata")}>
                    <Printer size={30} />
                  </UnstyledButton>
                </Tooltip>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>2</Table.Td>
              <Table.Td>Admission Letter</Table.Td>
              <Table.Td align="center">
                <Tooltip label="Download">
                  <UnstyledButton
                    onClick={() => handlePrintClick("admission_letter")}
                  >
                    <CloudDownload size={30} />
                  </UnstyledButton>
                </Tooltip>
              </Table.Td>
            </Table.Tr>
            {downloadData.map((row, key) => (
              <Table.Tr key={row.id}>
                <Table.Td>{key + 3}</Table.Td>
                <Table.Td>{row.description}</Table.Td>
                <Table.Td align="center">
                  <Tooltip label="Download">
                    <UnstyledButton
                      onClick={() => handlePrintClick("files", row)}
                    >
                      <CloudDownload size={30} />
                    </UnstyledButton>
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Container>
      <NoticeDialog
        opened={dialogOpen}
        buttonText="Close"
        dialogMessage={dialogMessage}
        handleOnClose={handleDialogClose}
        size="sm"
      />
    </div>
  );
}

export default AdmissionStatusDashboard;
