const regions =[
    
        {label:"Ashanti", value:"Ashanti"},
        {label:"Bono",value:"Bono"},
        {label:"Bono East", value:"Bono East"},
        {label:"Ahafo", value:"Ahafo"},
        {label:"Central", value:"Central"},
        {label:"Eastern", value:"Eastern"},
        {label:"Greater Accra", value:"Greater Accra"},
        {label:"Northern", value:"Northern"},
        {label:"Savannah", value:"Savannah"},
        {label:"North East", value:"North East"},
        {label:"Upper East", value:"Upper East"},
        {label:"Upper West", value:"Upper West"},
        {label:"Volta", value:"Volta"},
        {label:"Oti", value:"Oti"},
        {label:"Western", value:"Western"},
        {label:"Western North", value:"Western North"}
    
]

const physicalChallenge = [
    {label:'No Challenge', value:"No Challenge"},
    {label:'Partially Sighted', value:'Partially Sighted'},
    {label:'Non Sighted', value:'Non Signted'}
]

const guardian = [
    {label:"Father", value:'Father'},
    {label:"Mother", value:'Mother'},
    {label:"Other", value:'Other'}
]
 export {
    regions, physicalChallenge,
    guardian
}