import {
  Alert,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Image,
  SimpleGrid,
} from "@mantine/core";
import { Formik } from "formik";
import React, {  useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { sessionGetIsSubdomain } from "../../store/local/sessionStore";
import {  InputText } from "../../components/input";
import * as Yup from "yup";
import * as localStore from "../../store/local/localStore";
import * as apiAdmission from "../../api/admission";
import iconVoucher from "../../assets/voucher.png";
import {  useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import * as apiVoucher from "../../api/voucher";
import NoticeDialog from "../../components/dialogs/NoticeDialog";
import SchoolPictureCarousel from "../../components/school/SchoolPictureCarousel";

const validationSchema = Yup.object().shape({
  voucherNumber: Yup.string().required().label("Voucher Number"),
  jhsIndexNumber: Yup.string()
    .required()
    .min(12)
    .max(12)
    .label("JHS Index Number"),
});

function AdmissionStatusLogin() {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const isSubdomain = JSON.parse(sessionGetIsSubdomain());
  const [loading, setLoading] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();
  const [school, setSchool] = useState(localStore.getSchoolDetails());

  const [admissionStatus, setAdmissionStatus] = useState({
    jhsIndexNumber: "",
    voucherNumber: "",
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const response = await apiVoucher.verifyVoucher(values);

    if (!response.ok) {
      setLoading(false);
      notifications.show({
        title: "Error",
        message: response.data.message,
        color: "red",
      });
      return;
    }

    //if verification success,
    //check if user has already filled the form
    const sch = response.data;
    const checkForm = await apiAdmission.getProspectus({
      ...values,
      schoolId: sch.school.id,
    });
    if (!checkForm.ok) {
      //user has not filled form
      if (response.status !== 400) {
        setDialogMessage(
          <div className="text-center" style={{ fontSize: 18 }}>
            {checkForm.data.message}
          </div>
        );
        setDialogOpened(true);
      }
      setLoading(false);
      return;
    }
    //save data to session storage
    //   storeUser('user', JSON.stringify({ jhsIndexNumber: values.jhsIndexNumber, voucherNumber: values.voucherNumber }))
    localStore.storeStudentVoucherDetails({
      jhsIndexNumber: values.jhsIndexNumber,
      voucherNumber: values.voucherNumber,
    });
    if (!localStore.getSchoolDetails()) {
      // storeUser('school', JSON.stringify(sch))
      localStore.storeSchoolDetails(sch);
    }
    navigate(values.jhsIndexNumber);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };
  return (
    <div>
      <Formik
        initialValues={admissionStatus}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit, setValues, values }) => (
          <>
            <SimpleGrid
              cols={{sm: 2, xs: 1, md: 2, lg: 2, xl: 2 }}
              spacing="lg"
            >
              {!isSmallScreen && (
                <Flex
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  bg="#EEEEEE"
                  h="95vh"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <Grid justify="center" mt="10%">
                    <Grid.Col>
                      <SchoolPictureCarousel />
                    </Grid.Col>
                  </Grid>
                </Flex>
              )}
              <Flex
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Card>
                  <Center>
                    <Image
                      width={100}
                      height={100}
                      fit="contain"
                      src={
                        school && isSubdomain === true
                          ? school?.school?.logoUrl
                          : iconVoucher
                      }
                      alt="School logo"
                    />
                  </Center>
                  <Grid justify="center" mt={5}>
                    <Grid.Col
                      span={{ lg: 8, md: 8, sm: 12, xs: 12 }}
                      className="flex-center"
                    >
                      <Alert>
                        <ul>
                          {!school && (
                            <li>
                              Select the exact school you're placed from the
                              list below.
                            </li>
                          )}
                          <li>
                            Enter your 10 digits Index Number followed by the{" "}
                            <strong>year. eg. 100000024</strong>{" "}
                          </li>
                          <li>Enter your voucher number to download admission data</li>
                        </ul>
                      </Alert>
                      
                      <InputText
                        mt={10}
                        size="lg"
                        label="JHS Index Number"
                        name="jhsIndexNumber"
                        placeholder="10000000024"
                        maxLength={12}
                        defaultalue={admissionStatus.jhsIndexNumber}
                      />

                      <InputText
                        size="lg"
                        label="Voucher Number"
                        name="voucherNumber"
                        placeholder="Enter Voucher Number Received via SMS"
                        defaultalue={admissionStatus.voucherNumber}
                      />

                      <Button
                        size="lg"
                        mt={30}
                        type="button"
                        fullWidth
                        loading={loading}
                        variant="filled"
                        onClick={handleSubmit}
                      >
                        Download Forms
                      </Button>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Flex>
            </SimpleGrid>
          </>
        )}
      </Formik>
      <NoticeDialog
        opened={dialogOpened}
        buttonText="Close"
        dialogMessage={dialogMessage}
        handleOnClose={handleDialogClose}
        size="sm"
      />
    </div>
  );
}

export default AdmissionStatusLogin;
