import {
  Button,
  Center,
  Container,
  Stack,
  Stepper,
  Text,
} from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import StepTwoAdmissionFormPreview from "./StepTwoAdmissionFormPreview";
import StepOneAdmissionFormFill from "./StepOneAdmissionFormFill";
import * as localStore from "../../store/local/localStore";
import * as apiAdmission from "../../api/admission";
import NoticeDialog from "../../components/dialogs/NoticeDialog";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import PageTitle from "../../components/header/PageTitle";
import logger from "../../utils/logger";

function AdmissionFormDashboard() {
  const navigate = useNavigate();
  const [userVoucherDetails, setUserVoucherDetails] = useState(
    localStore.getStudentVoucherDetails()
  );
  const [school, setSchool] = useState(localStore.getSchoolDetails());
  const [student, setStudent] = useState(localStore.getStudentDetails());
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [data, setData] = useState({
    jhsIndexNumber: userVoucherDetails.jhsIndexNumber,
    voucherNumber: userVoucherDetails?.voucherNumber,
    schoolId: school?.school?.id,
    dateOfBirth: new Date(),
    placeOfBirth: "",
    hometown: "",
    nationality: "Ghana",
    address: "",
    phoneNumber: "",
    religiousDenomination: "",
    region: "",
    physicalChallenge: "",
    enrolmentCode: "",
    lastSchoolAttended: "",
    addressOfLastSchoolAttended: "",
    nameOfFather: "",
    occupationOfFather: "",
    addressOfFather: "",
    phoneNumberOfFather: "",
    nameOfMother: "",
    occupationOfMother: "",
    addressOfMother: "",
    phoneNumberOfMother: "",
    nameOfGuardian: "",
    occupationOfGuardian: "",
    phoneNumberOfGuardian: "",
    postalAddressOfGuardian: "",
    residentialAddressOfGuardian: "",
    guardian: "",
  });

  const onSubmit = (values) => {
    setData((data) => ({
      ...data,
      ...values,
    }));

    //move to the preview tab for the user to preview data
    logger("onSubmitClick", values);
    setActive(1);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
    navigate("/");
  };

  const onNextClick = () => {
    nextStep();
  };

  const onPreviousClick = () => {
    prevStep();
  };

  //if a user has already submitted their admission form, show this dialog and when its closed, go to home
  const showAlreadySubmittedFormModal = useCallback(() => {
    function navigateBack(modals) {
      navigate("/");
      modals.closeAll();
    }
    modals.open({
      title: "Information",
      closeOnClickOutside: false,
      onClose: () => {
        navigateBack(modals);
      },
      children: (
        <>
          <Text mb={20}>You have already submitted your admission details</Text>
          <Button
            fullWidth
            onClick={() => {
              navigateBack(modals);
            }}
          >
            Close
          </Button>
        </>
      ),
    });
  }, [navigate]);

  //get the user's admission data,
  //if the user has already filled the admission form, or there's an admission data,
  //show dialog that the user has already filled the form.
  const getAdmissionData = useCallback(async () => {
    const values = {
      schoolId: school?.school.id,
      jhsIndexNumber: userVoucherDetails?.jhsIndexNumber,
      voucherNumber: userVoucherDetails?.voucherNumber,
    };
    const response = await apiAdmission.getProspectus(values);
    logger("admission data", response.data);

    setData((prev) => ({
      ...prev,
      ...response.data,
    }));
    if (response.ok) {
      //move to the final step if there's an admission data
      nextStep();
      showAlreadySubmittedFormModal();
      return;
    }
  }, [
    school?.school.id,
    showAlreadySubmittedFormModal,
    userVoucherDetails?.jhsIndexNumber,
    userVoucherDetails?.voucherNumber,
  ]);

  useEffect(() => {
    getAdmissionData();
  }, [getAdmissionData]);

  return (
    <>
    <PageTitle title="Fill Form Dashboard"/>
      <Container size="lg" mt={50}>
        <Stepper
          active={active}
          onStepClick={setActive}
          allowNextStepsSelect={false}
        >
          <Stepper.Step
            label="Fill Admission Form"
            description="Fill the admission form by providing all the required information"
            allowStepSelect={false}
            allowStepClick={false}
          >
            <StepOneAdmissionFormFill
              admiData={data}
              student={student}
              onSubmit={onSubmit}
              onNextClick={onNextClick}
            />
          </Stepper.Step>
          <Stepper.Step
            label="Preview Details"
            description="Preview your data before submitting"
            allowStepSelect={false}
            allowStepClick={false}
          >
            <StepTwoAdmissionFormPreview
              admiData={data}
              student={student}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
            />
          </Stepper.Step>
          <Stepper.Completed>
            <Center mt={30}>
              <Stack>
                <Text size="xl">
                  Your application has been submitted successfully!
                </Text>
                {/* <Button
                  mt={20}
                  size="lg"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Finish
                </Button> */}
              </Stack>
            </Center>
          </Stepper.Completed>
        </Stepper>

      </Container>
      <NoticeDialog
        opened={dialogOpened}
        buttonText="Close"
        dialogMessage={dialogMessage}
        handleOnClose={handleDialogClose}
        size="sm"
      />
    </>
  );
}

export default AdmissionFormDashboard;
